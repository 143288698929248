import { defineStore } from 'pinia'

import tokenService from "@/services/identity/tokens.js";
import licenseService from "@/services/license/license.js"

const levels = ["n0", "n1", "n2", "n3", "n4", "n5"];

function isInRole(roles, role) {
    var isIn = roles.indexOf(role) > -1;
    return isIn;
}

function allowViewLevel(license, roles, level) {
    var allow = license[`allow${level.toUpperCase()}`];
    if (!allow) return false;

    allow = allowEditLevel(license, roles, level);
    if (allow) return true;

    //Get the high role
    let levelRoles = roles.filter(r => levels.indexOf(r) > -1)
    let maxLevel = levelRoles[levelRoles.length - 1];
    return level <= maxLevel;
}

function allowEditLevel(license, roles, level) {
    var allow = license[`allow${level.toUpperCase()}`];
    if (!allow) return false;

    allow = isSuperAdmin(roles) || isAdmin(roles);
    if (allow) return true;

    return isInRole(roles, level)
}

function isSuperAdmin(roles) {
    return isInRole(roles, "SuperAdmin")
}

function isAdmin(roles) {
    return isInRole(roles, "Admin")
}

function allow(roles, role) {
    if (isAdmin(roles)) { return true; }
    if (isSuperAdmin(roles)) { return true; }
    return isInRole(roles, role);
}

function parseRoles(roles) {
    for (let role of roles) {
        let index = levels.indexOf(role.toLowerCase())
        if (index > -1) {
            index = roles.indexOf(role);
            roles[index] = role.toLowerCase();
        }
    }

    return roles;
}

export const useIdentityStore = defineStore('identity', {
    state: () => ({
        authenticated: false,
        user: null,
        accessToken: null,
        exp: null,
        roles: [],
        levelsEdit: [],
        levelsView: [],
        license: {},
    }),
    actions: {
        addToken({ token }) {
            let user = tokenService.parseJwt(token);

            // Save license
            this.license = licenseService.parse(user.license);

            // Save user
            this.user = user;

            // Save roles
            let roles = user.role;
            if (roles !== undefined) {
                roles = typeof roles === "string" ? [roles] : roles.sort();
                this.roles = parseRoles(roles);

                let levelsEdit = [];
                let levelsView = [];
                for (const level of levels) {
                    if (allowEditLevel(this.license, roles, level)) {
                        levelsEdit.push(level);
                        levelsView.push(level);
                    } else if (allowViewLevel(this.license, roles, level)) {
                        levelsView.push(level);
                    }
                }
                this.levelsEdit = levelsEdit;
                this.levelsView = levelsView;
            }

            // Save token
            this.accessToken = token;
            this.authenticated = true;
            this.exp = user.exp;

            tokenService.storeTokenToStorage(token);
        },
        setLicense(token) {
            var license = licenseService.parse(token);
            this.license = license;
        },
        setGuestLevels(license) {
            let levels = [];

            if (license.allowN0) levels.push('n0');
            if (license.allowN1) levels.push('n1');
            if (license.allowN2) levels.push('n2');
            if (license.allowN3) levels.push('n3');
            if (license.allowN4) levels.push('n4');
            if (license.allowN5) levels.push('n5');

            this.levelsEdit = this.levelsView = levels;
        },
        logout() {
            this.accessToken = null;
            this.user = null;
            this.authenticated = false;
            this.exp = null;
            this.roles = [];
            this.levelsEdit = [];
            this.levelsView = [];
            this.icense = {};
            tokenService.removeTokenFromStorage();
        }
    },
    getters: {
        isAuthenticated: state => state.authenticated,
        getUser: state => state.user,
        getUserId: state => state.user?.sub,
        getAccesToken: state => state.accessToken,
        getExpiration: state => state.exp,
        getLicense: state => state.license,

        allowPremium: state => state.license.allowPremium,
        allowViewLevel: state => level => state.levelsView.indexOf(level) > -1,
        allowEditLevel: state => level => state.levelsEdit.indexOf(level) > -1,

        getViewLevels: state => state.levelsView,
        getEditLevels: state => state.levelsEdit,

        isSuperAdmin: state => isSuperAdmin(state.roles),
        isAdmin: state => isAdmin(state.roles),

        allowUsers: state => state.license.allowUsers && (isAdmin(state.roles) || isSuperAdmin(state.roles)),

        allowPlayerAverage: state => allow(state.roles, "PlayerAverage"),
        allowScoutComparer: state => state.license.allowScoutsComparer && allow(state.roles, "ScoutComparer"),
        allowPlayerComparer: state => state.license.allowPlayersComparer && allow(state.roles, "PlayerComparer"),
        allowSearch: state => allow(state.roles, "Search"),
        allowLineup: state => state.license.allowField && allow(state.roles, "Lineup"),
        allowRecords: state => state.license.allowRecords,
        allowHistorical: state => state.license.allowHistorical && allow(state.roles, "Historical"),
        allowCreate: state => state.license.allowCreate && allow(state.roles, "Create"),
        allowUpdate: state => state.license.allowUpdate && allow(state.roles, "Update"),
        allowDelete: state => state.license.allowDelete && allow(state.roles, "Delete"),
        allowShare: state => state.license.allowShare && allow(state.roles, "Share"),
        allowSecondPosition: state => state.license.allowSecondPosition,
        allowPlayerKnowledge: state => state.license.allowPlayerKnowledge,
        allowTeamPreferences: state => state.license.allowTeamPreferences,
        allowClub: state => state.license.allowClub,
        allowPlayerTransference: state => state.license.allowPlayerTransference,
        allowMultilanguage: state => state.license.allowMultilanguage,
    }
});