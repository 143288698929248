<template>
    <div class="col-auto centered-content">
        <button class="btn btn-primary" @click="open">
            {{ $t("records.add") }}
        </button>

        <div
            class="modal fade"
            id="create-record-modal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
        >
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header primary">
                        <h5 class="modal-title fs-5">
                            {{ $t("record.create") }}
                        </h5>
                        <button class="btn-close" @click="close" />
                    </div>

                    <div class="modal-body">
                        <div>
                            <TextInput
                                v-model:text="name"
                                :label="$t('record.name')"
                            />
                        </div>

                        <div class="mt-3">
                            <CustomSwitch
                                v-model:checked="isPrivate"
                                :left="$t('record.public')"
                                :right="$t('record.private')"
                            />
                        </div>

                        <div class="centered-content mt-3">
                            <ImageInput v-model:file="logo" />
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button
                            class="btn btn-primary"
                            :disabled="!canSave || saving"
                            @click="save"
                        >
                            <div v-if="saving" class="px-2">
                                <span
                                    class="spinner-border spinner-border-sm"
                                />
                            </div>
                            <span v-else>{{ $t("common.save") }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from "bootstrap";
import { RepositoryFactory } from "@/services/api";

export default {
    name: "create-record",
    data() {
        return {
            modal: null,
            saving: false,

            name: "",
            isPrivate: false,
            type: 0,

            logo: null,
        };
    },
    mounted() {
        this.modal = new Modal("#create-record-modal");
    },
    computed: {
        canSave() {
            return this.name !== "";
        },
    },
    beforeUnmount() {
        this.close();
    },
    watch: {
        isPrivate() {
            this.type = this.isPrivate ? 1 : 0;
        },
    },
    methods: {
        async save() {
            this.saving = true;
            try {
                let payload = {
                    record: {
                        name: this.name,
                        type: this.isPrivate ? 1 : 0,
                    },
                };

                let formData = new FormData();
                formData.append("json", JSON.stringify(payload));

                let logo = this.logo;
                if (logo === null) {
                    var base64 = require("@/assets/images/default/record.png");
                    var blob = await (await fetch(base64)).blob();
                    logo = new File([blob], "logo.png");
                }
                formData.append("logo", logo);

                let response = await RepositoryFactory.record.add(formData);
                let id = response.data;

                this.$notify.success(this.$t("record.success.create"));

                this.$router.push({
                    name: "record",
                    params: { id: id },
                });
            } catch {
                this.$notify.error(this.$t("record.error.create"));
            }
            this.saving = false;
        },
        open() {
            this.modal.show();
        },
        close() {
            this.modal.hide();

            this.name = "";
            this.logo = null;
            this.type = 0;
        },
    },
};
</script>