<template>
    <div v-if="loading">
        <Loading />
    </div>

    <Transition name="fade" mode="out-in">
        <div class="text-center">
            <img
                ref="logo"
                :src="url"
                :class="logoClass"
                @click="click"
                loading="lazy"
                alt=""
            />
        </div>
    </Transition>
</template>

<script>
import { getLogoUrl } from "@/services/images";

export default {
    name: "logo",
    props: {
        id: {
            type: String,
            required: true,
        },
        extension: {
            type: String,
            required: true,
        },
        type: {
            type: Number,
            required: true,
        },
        size: {
            type: [String, Number],
        },
        rounded: {
            type: Boolean,
            default: false,
        },
        bordered: {
            type: Boolean,
            default: false,
        },
        helpClass: {
            type: String,
            default: "",
        },
    },
    emits: ["click"],
    data() {
        return {
            loading: true,
        };
    },
    computed: {
        url() {
            return getLogoUrl(this.id, this.extension, this.type, this.size);
        },
        logoClass() {
            let logoClass = this.helpClass;

            if (this.rounded) logoClass += " rounded-logo";
            if (this.bordered) logoClass += " shadow-border";

            return logoClass.trim();
        },
    },
    mounted() {
        this.$refs.logo.addEventListener("load", this.imageLoaded);
    },
    methods: {
        imageLoaded() {
            this.loading = false;
        },
        click() {
            this.$emit("click");
        },
    },
};
</script>

<style lang="scss" scoped>
.rounded-logo {
    border-radius: 50%;
}

.shadow-border {
    background-color: whitesmoke;
}
</style>