import Repository from "./repository"

import { buildPaginationQuery } from "../pagination/index";

const resource = "record"

export default {
    async add(payload) {
        Repository.headers = {
            ...Repository.headers,
            'Content-Type': 'multipart/form-data'
        };
        let endpoint = `${resource}`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async get({ id }) {
        let endpoint = `${resource}/${id}`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async update({ id, payload }) {
        Repository.headers = {
            ...Repository.headers,
            'Content-Type': 'multipart/form-data'
        };
        let endpoint = `${resource}/${id}`;
        let response = await Repository.put(endpoint, payload);
        return response;
    },
    async updateUser({ id, payload }) {
        let endpoint = `${resource}/${id}/users`;
        let response = await Repository.put(endpoint, payload);
        return response;
    },
    async getPlayers(params) {
        let endpoint = `${resource}/${params.id}/players`;
        let average = params.average;
        if (average !== undefined && average !== null) {
            endpoint += `?average=${average}`;
        }
        let response = await Repository.get(endpoint);
        return response;
    },
    async addPlayer({ recordId, playerId }) {
        let endpoint = `${resource}/${recordId}/player/${playerId}`;
        let response = await Repository.put(endpoint);
        return response;
    },
    async deletePlayer({ recordId, playerId }) {
        let endpoint = `${resource}/${recordId}/player/${playerId}`;
        let response = await Repository.delete(endpoint);
        return response;
    },
    async delete({ id }) {
        let endpoint = `${resource}/${id}`;
        let response = await Repository.delete(endpoint);
        return response;
    },
    async getPaged(params, type) {
        let endpoint = `${resource}/paged/${type}`;
        endpoint = buildPaginationQuery(endpoint, params);
        let category = params.category;
        if (category !== undefined && category !== "" && category !== null) {
            endpoint += `&category=${category}`;
        }
        let response = await Repository.get(endpoint);
        return response;
    },
    async getCreatedPaged(params) {
        return await this.getPaged(params, 0);
    },
    async getSharedPaged(params) {
        return await this.getPaged(params, 1);
    },
    async getPublicPaged(params) {
        return await this.getPaged(params, 2);
    },
    async getAllPaged(params) {
        return await this.getPaged(params, 3);
    },
    async setMainRecord({ id }) {
        let endpoint = `${resource}/${id}/main`;
        let response = await Repository.put(endpoint);
        return response;
    },
    async getMainRecord() {
        let endpoint = `${resource}/main`;
        let response = await Repository.get(endpoint);
        if (response.status === 204) {
            response.data = null;
        }
        return response;
    }
}