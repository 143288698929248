<template>
    <div class="nav-item dropdown me-2">
        <div
            class="nav-link"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
        >
            <Icon name="gear" size="2x" helperClass="text-gray" />
        </div>
        <div class="dropdown-menu dropdown-menu-end background-gray">
            <CustomSwitch
                v-model:checked="isPremium"
                left="Básico"
                right="Premium"
            />
        </div>
    </div>
</template>

<script>
import { usePreferencesStore } from "@/stores/preferences";

export default {
    name: "navbar-settings",
    data() {
        return {
            preferencesStore: usePreferencesStore(),
            isPremium: usePreferencesStore().isPremium,
        };
    },
    watch: {
        isPremium() {
            if (this.isPremium) this.preferencesStore.setPremium();
            else this.preferencesStore.setBasic();
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";

.dropdown-menu {
    position: absolute;
    margin-top: 0;
}
</style>