function wait(time) {
    return new Promise(resolve => setTimeout(resolve, time));
}

function uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

function shuffle(array) {
    let currentIndex = array.length, randomIndex;

    while (currentIndex > 0) {

        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }

    return array;
}

function getRoundedScoreToDecimals(value) {
    return Math.round(value * 100) / 100;
}

export const helpers = {
    wait,
    getRoundedScoreToDecimals,
    shuffle,
    uuidv4
}