<template>
    <div class="page-container">
        <VerticalNavbar />
        <div class="page-content-with-horizontal-navbar">
            <HorizontalNavbar />
            <div class="page-content">
                <slot />
            </div>
            <AddPlayerToRecord ref="addToRecord" />
        </div>
    </div>
</template>

<script>
import VerticalNavbar from "./VerticalNavbar.vue";
import HorizontalNavbar from "./HorizontalNavbar.vue";
import AddPlayerToRecord from "@/components/widgets/records/AddPlayerToRecord.vue";

import { userUsersStore } from "@/stores/users";

export default {
    name: "layout-default",
    components: {
        VerticalNavbar,
        HorizontalNavbar,
        AddPlayerToRecord,
    },
    mounted() {
        this.loadData();
    },
    methods: {
        loadData() {
            userUsersStore().loadUsers();
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";

.page-content-with-horizontal-navbar {
    width: 100%;

    overflow: hidden;
}

.page-content {
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 0.35rem;
        height: 0.35rem;
    }

    &::-webkit-scrollbar-track {
        background: #1e1e24;
    }

    &::-webkit-scrollbar-thumb {
        background: $primary;
    }

    &::-webkit-scrollbar-corner {
        background: #1e1e24;
    }
}

/* Large screens */
@media only screen and (min-width: 768px) {
    .page-container {
        display: flex;
    }

    .page-content {
        width: 100%;

        height: calc(100vh - 55px);

        padding-bottom: 2rem;
    }
}

/* Small screens */
@media only screen and (max-width: 767px) {
    .page-content {
        width: 100%;

        height: calc(100vh - 4rem - 55px); // sub navbars (top and bottom)

        padding-bottom: 4rem;
    }
}
</style>