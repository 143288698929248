import { defineStore } from 'pinia'

import { RepositoryFactory } from "@/services/api";

export const userUsersStore = defineStore('users', {
    state: () => ({
        users: []
    }),
    actions: {
        async loadUsers() {
            if (this.users.length !== 0) return;

            let response = await RepositoryFactory.identity.getUsers({});
            this.users = response.data.map(user => {
                user.completeName = `${user.name} ${user.subnames}`.trim();
                return user;
            });
        }
    },
    getters: {
        getUsers: state => state.users,
        getUser: state => id => state.users.find(u => u.id === id) ?? null
    }
});