<template>
    <thead>
        <tr>
            <th
                v-for="column in columns"
                :key="column.name"
                :class="[
                    column.class ?? defaultClass,
                    { 'is-pointer': column.sortable },
                ]"
                @click="setSort(column)"
            >
                <span
                    v-if="
                        column.sortable &&
                        sort.by !== null &&
                        sort.by === column.name
                    "
                    class="me-1"
                >
                    <Icon v-if="sort.order === 'asc'" name="caret-up" />
                    <Icon v-else-if="sort.order === 'desc'" name="caret-down" />
                </span>
                <span v-if="column.title !== ''">
                    {{ $t(`tables.${column.title}`) }}
                </span>
            </th>
        </tr>
    </thead>
</template>

<script>
export default {
    name: "scoutyou-table-header",
    props: {
        columns: {
            type: Array,
            default: () => [],
        },
        sorting: {
            type: Object,
            default: () => {},
        },
    },
    emits: ["update:sorting"],
    data() {
        return {
            defaultClass: "text-start",
            sort: {},
        };
    },
    mounted() {
        this.sort = this.sorting;
    },
    methods: {
        setSort(column) {
            if (!column.sortable) return;

            if (column.name === this.sort.by) {
                this.sort.order = this.sort.order === "asc" ? "desc" : "asc";
            } else {
                this.sort.by = column.name;
                this.sort.order = "desc";
            }

            this.$emit("update:sorting", this.sort);
        },
    },
};
</script>