<template>
    <div v-if="loading">
        <Loading />
    </div>
    <div class="text-center" v-show="!loading">
        <img
            ref="logo"
            :src="url"
            class="user-logo"
            :class="logoClass"
            @click="click"
        />
    </div>
</template>

<script>
import { getUserLogoUrl } from "@/services/images";

export default {
    name: "user-logo",
    props: {
        id: {
            type: String,
            required: true,
        },
        extension: {
            type: String,
            required: true,
        },
        size: {
            type: [String, Number],
        },
        rounded: {
            type: Boolean,
            default: false,
        },
        helpClass: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            loading: true,
        };
    },
    computed: {
        url() {
            return getUserLogoUrl(this.id, this.extension, this.size);
        },
        logoClass() {
            let logoClass = this.helpClass;
            if (this.rounded) {
                logoClass += " rounded-logo";
            }
            return logoClass.trim();
        },
    },
    mounted() {
        this.$refs.logo.addEventListener("load", this.imageLoaded);
    },
    methods: {
        imageLoaded() {
            this.loading = false;
        },
        click() {
            this.$emit("click");
        },
    },
};
</script>

<style lang="scss" scoped>
.user-logo {
    border-radius: 50%;
}
</style>