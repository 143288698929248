export function buildPaginationQuery(endpoint, { page, take, search, sort }) {
    endpoint += `?page=${page}&take=${take}`;

    if (search !== undefined && search !== "" && search !== null) {
        endpoint += `&search=${search}`;
    }

    const sortBy = sort?.by ?? null;
    if (sortBy !== undefined && sortBy !== "" && sortBy !== null) {
        endpoint += `&sortBy=${sortBy}`;
    }

    const sortOrder = sort?.order ?? null;
    if (sortOrder !== undefined && sortOrder !== "" && sortOrder !== null) {
        endpoint += `&sortOrder=${sortOrder}`;
    }
    return endpoint;
}

export function paginate(array, page_number, page_size) {
    return array.slice((page_number - 1) * page_size, page_number * page_size);
} 