import { defineStore } from 'pinia'

export const useRecordsStore = defineStore('records', {
    state: () => ({
        playerId: null
    }),
    getters: {
        getPlayerId: (state) => state.playerId,
    },
    actions: {
        setPlayerId(playerId) {
            this.playerId = playerId;
        }
    }
})