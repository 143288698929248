<template>
    <div class="nav-item dropdown">
        <div
            id="profile-dropdown"
            class="nav-link"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
        >
            <Icon name="circle-user" size="2x" helperClass="text-gray" />
        </div>
        <div class="dropdown-menu dropdown-menu-end background-gray">
            <div class="text-center">
                <div v-if="user" class="user-info">
                    <UserLogo
                        :id="user.sub"
                        :extension="user.picture"
                        :size="100"
                    />
                    <h5 class="pt-1">¡Hola, {{ user.name }}!</h5>
                    <p class="email">{{ user.email }}</p>
                </div>
                <div class="mx-1">
                    <button
                        class="btn btn-outline-primary text-black"
                        @click="goProfile"
                    >
                        <Icon name="user" />
                        <span class="ms-2">{{ $t("navbar.profile") }}</span>
                    </button>
                    <button
                        class="btn btn-outline-primary text-black mt-1"
                        @click="logout"
                    >
                        <Icon name="right-from-bracket" />
                        <span class="ms-2">{{ $t("navbar.logout") }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { RepositoryFactory } from "@/services/api";
import { useIdentityStore } from "@/stores/identity";
import { Dropdown } from "bootstrap";

import tokenService from "@/services/identity/tokens.js";

export default {
    name: "user-info",
    data() {
        return {
            dropdown: null,
            timer: null,
            identityStore: useIdentityStore(),
        };
    },
    mounted() {
        this.checkSesionExpiration();
        this.startTimer();

        this.dropdown = new Dropdown("#profile-dropdown");
    },
    beforeUnmount() {
        this.stopTimer();
    },
    computed: {
        user() {
            return this.identityStore.getUser;
        },
    },
    methods: {
        checkSesionExpiration() {
            let expiration = this.identityStore.getExpiration;
            let expired = tokenService.isExpired(expiration);
            if (expired) {
                this.logout();
            } else {
                let remaining = tokenService.getRemaining(expiration);
                if (remaining < 10 * 60) {
                    this.refreshToken();
                }
            }
        },
        async refreshToken() {
            try {
                let response = await RepositoryFactory.identity.refresh();
                let token = response.data;
                this.identityStore.addToken({ token });
                window.console.log("Refreshed token!");
            } catch (error) {
                if (error.response?.status === 401) {
                    this.logout();
                }
            }
        },
        goProfile() {
            this.$router.push({
                name: "profile",
            });
            this.dropdown.hide();
        },
        startTimer() {
            this.timer = setInterval(() => {
                this.checkSesionExpiration();
            }, 60 * 1000);
        },
        stopTimer() {
            clearInterval(this.timer);
        },
        logout() {
            this.$router.push({ name: "logout" });
        },
    },
};
</script>

<style lang="scss" scoped>
.dropdown-menu {
    position: absolute;
    margin-top: 0;
}

.email {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 0.8rem;
}

.btn-outline-primary {
    border-width: 0.15rem;
    border-radius: 20px;
}
</style>