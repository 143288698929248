export const categories = [
    { id: 0, key: "senior" },
    { id: 1, key: "juvenile" },
    { id: 2, key: "cadet" },
    { id: 3, key: "infant" },
    { id: 4, key: "alevin" },
    { id: 5, key: "benjamin" },
    { id: 6, key: "prebenjamin" },
    { id: 7, key: "initiation" }
]

export function getCategory(id) {
    let category = categories.find(p => p.id === id);
    return category;
}