<template>
    <div class="file-input" @click="open">
        <Icon name="upload" size="2x" />

        <div class="mt-2 fw-bold">
            {{ $t("bulk-upload.select-file") }}
        </div>
    </div>

    <input
        ref="fileInput"
        class="hidden"
        type="file"
        :accept="formats"
        @change="onChange"
    />
</template>

<script>
export default {
    name: "file-input",
    props: {
        file: {
            type: File,
            default: null,
        },
        formats: {
            type: String,
            default: ".xls,.xlsx",
        },
    },
    emits: ["update:file"],
    methods: {
        open() {
            this.$refs.fileInput.click();
        },
        onChange(event) {
            let file = event.target.files[0];
            this.$emit("update:file", file);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";

.file-input {
    cursor: pointer;
    text-align: center;

    border: 3px dashed $primary;
    border-radius: 10px;

    padding: 2rem;

    &:hover {
        background-color: $background-gray;
        div {
            color: $primary;
        }
    }
}

.hidden {
    display: none;
}
</style>