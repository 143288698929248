<template>
    <div class="text-center text-primary my-1">
        <Icon name="futbol" size="2x" spin />
    </div>
</template>

<script>
export default {
    name: "loading",
};
</script>
