const tokenKey = "scoutyoupro:access_token";

function readTokenFromStorage() {
    return localStorage[tokenKey];
}

function removeTokenFromStorage() {
    localStorage.removeItem(tokenKey);
}

function storeTokenToStorage(token) {
    localStorage[tokenKey] = token;
}

function getNowEpoch() {
    const now = new Date();
    return Math.round(now.getTime() / 1000);
}

function isExpired(exp) {
    let now = getNowEpoch();
    let isExpired = now > exp;
    return isExpired;
}

function getRemaining(exp) {
    let now = getNowEpoch();
    let remaining = exp - now;
    return remaining;
}

function parseJwt(token) {
    try {
        let base64Url = token.split('.')[1]
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var payload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(payload);
    } catch (error) {
        return null;
    }
}

export default {
    storeTokenToStorage,
    readTokenFromStorage,
    removeTokenFromStorage,
    isExpired,
    getRemaining,
    parseJwt
}