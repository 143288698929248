<template>
    <div v-if="name !== null" class="placeholder-container">
        <Transition name="fade" mode="out-in">
            <CustomIcon
                :name="name"
                :width="size"
                :height="size"
                :helperClass="helpClass"
                @click="click"
            />
        </Transition>
    </div>
</template>

<script>
export default {
    name: "logo-placeholder",
    props: {
        type: {
            type: Number,
            required: true,
        },
        size: {
            type: [String, Number],
        },
        rounded: {
            type: Boolean,
            default: false,
        },
        bordered: {
            type: Boolean,
            default: false,
        },
        helpClass: {
            type: String,
            default: "",
        },
    },
    emits: ["click"],
    computed: {
        name() {
            if (this.type === 0) return "trophy";
            if (this.type === 1) return "shield";
            if (this.type === 2) return "player";
            return null;
        },
    },
    methods: {
        click() {
            this.$emit("click");
        },
    },
};
</script>

<style lang="scss" scoped>
.placeholder-container {
    border: 3px dashed rgb(150, 150, 150);
    border-radius: 10px;

    img {
        padding: 0.25rem;
    }
}
</style>