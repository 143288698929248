<template>
    <div v-if="isPlayer" class="custom-breakdown">
        <div
            v-for="route in playerRoutes"
            :key="route"
            class="route"
            :class="{ active: isActive(route) }"
            @click="go(route)"
        >
            {{ this.$t(`navbar.${route}`) }}
        </div>
    </div>
    <div v-else>
        <div v-for="breadcrumb in breadcrumbs" :key="breadcrumb">
            {{ breadcrumb }}
        </div>
    </div>
</template>

<script>
import { useRecordsStore } from "@/stores/records";

export default {
    name: "breadcrumb-navbar",
    data() {
        return {
            recordStore: useRecordsStore(),
        };
    },
    computed: {
        playerRoutes() {
            let routes = ["player", "scout", "player-matches"];

            if (this.$permissions.allowPlayerComparer())
                routes.push("players-comparer");
            if (this.$permissions.allowScoutComparer())
                routes.push("player-scouters-comparer");
            if (this.$permissions.allowHistorical())
                routes.push("player-historical");
            if (this.$permissions.allowPlayerAverage())
                routes.push("player-average");
            if (this.$permissions.allowRecords())
                routes.push("player-add-to-record");

            return routes;
        },
        isPlayer() {
            return this.playerRoutes.indexOf(this.$route.name) > -1;
        },
        isMatch() {
            return this.$route.name === "match";
        },
        breadcrumbs() {
            let route = this.$route;

            if (route.name === undefined) return [];

            let breadcrumbs = [this.$t(`navbar.${route.name}`)];
            return breadcrumbs;
        },
    },
    methods: {
        go(route) {
            if (route === "player-add-to-record") {
                this.recordStore.setPlayerId(this.$route.params.playerId);
                return;
            }
            let query = this.$route.params.position
                ? { position: this.$route.params.position }
                : this.$route.query;
            this.$router.push({
                name: route,
                params: { playerId: this.$route.params.playerId },
                query: query,
            });
        },
        isActive(route) {
            return this.$route.name === route;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";

.custom-breakdown {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
}

.route {
    flex: 0 0 auto;
    cursor: pointer;

    padding: 0.2rem 0.5rem;
    margin: 0rem 0.2rem;
    border: 2px solid transparent;
    border-radius: 20px;
    font-size: 1.1rem;

    text-transform: lowercase;
    &::first-letter {
        text-transform: uppercase !important;
    }

    &.active {
        background-color: $primary;
        border-color: $primary;
    }

    &:hover:not(.active) {
        background-color: $secondary;
        border-color: $secondary;
    }
}
</style>