const imagesUrl = process.env.VUE_APP_IMAGES_SERVICE_URL;
const usersUrl = process.env.VUE_APP_USERS_IMAGES_SERVICE_URL;

export const types = [
    "league", "team", "player", "user", "record"
]

export function getLogoUrl(id, extension, type, size) {
    let url = "";
    if (size === undefined || size === 0) {
        url = `${imagesUrl}/${types[type]}/${id}${extension}`;
    } else {
        url = `${imagesUrl}/${types[type]}/${id}_${size}${extension}`;
    }
    return url;
}

export function getUserLogoUrl(id, extension, size) {
    let url = "";
    if (size === undefined || size === 0) {
        url = `${usersUrl}/${id}${extension}`;
    } else {
        url = `${usersUrl}/${id}_${size}${extension}`;
    }
    return url;
}