function parse(token) {
    var license = {};

    let tokens = token.split('-');

    parseLevels(license, tokens[0]);
    parseScoutType(license, tokens[1]);
    parseAdvancedSearch(license, tokens[2]);
    parseCRUD(license, tokens[3]);
    parseComparers(license, tokens[4]);
    parsePermissions(license, tokens[5]);

    return license;
}

function parseLevels(license, levels) {
    license.allowN0 = levels.indexOf('0') > -1;
    license.allowN1 = levels.indexOf('1') > -1;
    license.allowN2 = levels.indexOf('2') > -1;
    license.allowN3 = levels.indexOf('3') > -1;
    license.allowN4 = levels.indexOf('4') > -1;
    license.allowN5 = levels.indexOf('5') > -1;
}

function parseScoutType(license, scoutTypes) {
    license.allowPremium = scoutTypes.indexOf('P') > -1;
}

function parseAdvancedSearch(license, advancedSearch) {
    license.isSimpleAdvancedSearch = advancedSearch === 'S';
}

function parseCRUD(license, crud) {
    license.allowCreate = crud.indexOf('C') > -1;
    license.allowUpdate = crud.indexOf('U') > -1;
    license.allowDelete = crud.indexOf('D') > -1;
    license.allowShare = crud.indexOf('S') > -1;
}

function parseComparers(license, comparers) {
    license.allowScoutsComparer = comparers.indexOf('S') > -1;
    license.allowPlayersComparer = comparers.indexOf('P') > -1;
}

function parsePermissions(license, permissions) {
    license.allowUsers = permissions[0] === '1';
    license.allowSecondPosition = permissions[1] === '1';
    license.allowPlayerKnowledge = permissions[2] === '1';
    license.allowField = permissions[3] === '1';
    license.allowTeamPreferences = permissions[4] === '1';
    license.allowClub = permissions[5] === '1';
    license.allowPlayerTransference = permissions[6] === '1';
    license.allowMultilanguage = permissions[7] === '1';
    license.allowRecords = permissions[8] === '1';
    license.allowHistorical = permissions[9] === '1';
}

export default {
    parse
}