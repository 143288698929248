<template>
    <div class="row justify-content-center mb-2">
        <div class="col-12 col-sm-7">
            <div class="input-group p-2">
                <input
                    type="text"
                    v-model="search"
                    class="form-control small-input-text"
                    :placeholder="$t('tables.search')"
                    @keypress.enter="getItems"
                />
                <span
                    v-if="search !== ''"
                    class="input-group-text is-pointer"
                    @click="clearSearch"
                >
                    <Icon name="x" helperClass="text-danger" />
                </span>
                <span class="input-group-text is-pointer" @click="getItems">
                    <Icon name="magnifying-glass" />
                </span>
            </div>
        </div>
        <CreateRecord v-if="showCreate" />
    </div>

    <Transition name="fade" mode="out-in" :duration="250">
        <div v-if="isLoading">
            <Loading />
            <h6 class="text-primary text-center">
                {{ $t("records.loading") }}
            </h6>
        </div>
        <div v-else class="list">
            <div
                v-for="item in listItems"
                :key="item.id"
                class="list-item row"
                @click="clicked(item)"
            >
                <div class="col-3">
                    <div class="centered-content">
                        <Logo
                            :id="item.id"
                            :extension="item.logoExtension"
                            :size="100"
                            :type="4"
                            rounded
                            bordered
                            helpClass="logo-75"
                        />
                    </div>
                    <div class="text-center mt-1">
                        <Icon
                            v-if="isOwner(item)"
                            name="user"
                            helperClass="px-1 text-primary"
                        />
                        <Icon
                            v-if="isPrivate(item)"
                            name="lock"
                            helperClass="px-1 font-scout-yellow"
                        />
                    </div>
                </div>
                <div class="col-7 col-sm-5 vertical-centered-content">
                    <h6 class="text-center">
                        {{ item.name }}
                    </h6>
                </div>
                <div class="col-2 col-sm-4 centered-content">
                    <div class="badge text-bg-secondary">
                        <CustomIcon name="player" :inverted="false" />
                        <div class="count">
                            {{ item.players }}
                        </div>
                    </div>

                    <div class="badge text-bg-secondary">
                        <CustomIcon name="user" :inverted="false" />
                        <div class="count">{{ item.users }}</div>
                    </div>
                </div>
            </div>
            <div v-if="listItems.length === 0">
                <h6 class="empty-records">
                    {{ $t("records.empty") }}
                </h6>
            </div>
        </div>
    </Transition>

    <nav v-if="!isLoading" class="row">
        <div class="col text-center">
            {{ tableSummary }}
        </div>
        <div class="col-auto">
            <div class="pagination justify-content-end is-pointer">
                <div class="page-item" @click="first()">
                    <div class="page-link">
                        <Icon name="chevron-left" />
                        <Icon name="chevron-left" />
                    </div>
                </div>
                <div class="page-item" @click="prev()">
                    <div class="page-link">
                        <Icon name="chevron-left" />
                    </div>
                </div>

                <div
                    v-for="i in avaiablePages"
                    :key="i"
                    class="page-item"
                    :class="{ active: i === page }"
                    @click="go(i)"
                >
                    <div class="page-link">{{ i }}</div>
                </div>

                <div class="page-item" @click="next()">
                    <div class="page-link">
                        <Icon name="chevron-right" />
                    </div>
                </div>
                <div class="page-item" @click="last()">
                    <div class="page-link">
                        <Icon name="chevron-right" />
                        <Icon name="chevron-right" />
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import { useIdentityStore } from "@/stores/identity";

import CreateRecord from "../../../create-record/CreateRecord.vue";

export default {
    name: "paged-records",
    components: {
        CreateRecord,
    },
    emits: ["click", "update:page"],
    props: {
        isLoading: {
            type: Boolean,
            default: true,
        },
        page: {
            type: Number,
            default: 1,
        },
        take: {
            type: Number,
            default: 10,
        },
        total: {
            type: Number,
            default: 0,
        },
        items: {
            type: [Array, Function],
        },
        loadOnStart: {
            type: Boolean,
            default: true,
        },
        showCreate: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            search: "",
            listItems: [],

            identityStore: useIdentityStore(),
        };
    },
    mounted() {
        if (this.loadOnStart) this.getItems();
    },
    computed: {
        pages() {
            return Math.ceil(this.total / this.take);
        },
        avaiablePages() {
            const current = this.page;
            const total = this.pages;

            if (total <= 1) return [1];

            const center = [
                current - 2,
                current - 1,
                current,
                current + 1,
                current + 2,
            ];
            const filteredCenter = center.filter((p) => p > 1 && p < total);
            const includeThreeLeft = current === 5;
            const includeThreeRight = current === total - 4;
            const includeLeftDots = current > 5;
            const includeRightDots = current < total - 4;

            if (includeThreeLeft) filteredCenter.unshift(2);
            if (includeThreeRight) filteredCenter.push(total - 1);

            if (includeLeftDots) filteredCenter.unshift("...");
            if (includeRightDots) filteredCenter.push("...");

            return [1, ...filteredCenter, total];
        },
        tableSummary() {
            let page = this.page;
            let take = this.take;
            let total = this.total;

            let start = (page - 1) * take + 1;
            let end = page * take;
            if (end > total) {
                end = total;
            }

            return this.$t("tables.summary")
                .replace("#start#", start)
                .replace("#end#", end)
                .replace("#total#", total);
        },
    },
    watch: {
        page() {
            this.getItems();
        },
        take() {
            this.getItems();
        },
    },
    methods: {
        async refresh() {
            await this.getItems();
        },
        async clearSearch() {
            this.search = "";
            await this.getItems();
        },
        async getItems() {
            let items = [];
            if (typeof this.items === "function") {
                items = await this.items({
                    page: this.page,
                    take: this.take,
                    search: this.search,
                });
            } else {
                items = this.items;
            }
            this.listItems = items;
        },
        isOwner(record) {
            return this.identityStore.getUserId === record.createdBy;
        },
        isPrivate(record) {
            return record.type === 1;
        },
        clicked(item) {
            this.$emit("click", item);
        },
        first() {
            if (this.page > 1) this.go(1);
        },
        prev() {
            if (this.page > 1) this.go(this.page - 1);
        },
        go(page) {
            this.$emit("update:page", page);
        },
        next() {
            if (this.page < this.pages) this.go(this.page + 1);
        },
        last() {
            if (this.page < this.pages) this.go(this.pages);
        },
    },
};
</script>

<style lang="scss" scoped>
.empty-records {
    text-align: center;

    background-color: rgb(230, 230, 230);

    padding: 1rem;
    border-radius: 10px;
}

.list {
    padding-left: 1.25rem;
    padding-right: 1.25rem;

    .list-item {
        cursor: pointer;
        box-shadow: 0rem 0rem 0.25rem gray;
        border-radius: 10px;

        padding: 0.5rem;
        margin-bottom: 1rem;

        .count {
            font-size: 1.1rem;
            margin-top: 0.1rem;
            margin-bottom: 0.1rem;
        }

        &:hover {
            box-shadow: 0rem 0rem 0.6rem gray;
        }
    }
}

.badge {
    margin: 0.1rem 0rem;
    padding: 0.3rem;

    .count {
        display: inline-block;

        vertical-align: middle;
        color: black;
        padding: 0rem 0.25rem;
        font-size: 1.22525rem;
    }
}

.badge + .badge {
    margin-left: 0.25rem;
}
</style>