<template>
    <div class="nav-item me-2 mt-0" v-if="updateExists">
        <button class="btn btn-primary" @click="refreshApp" variant="primary">
            <span v-if="!refreshing">{{ $t("common.new-version") }}</span>
            <Icon v-else name="rotate" spin />
        </button>
    </div>
</template>

<script>
export default {
    name: "new-version-pop-up",
    data() {
        return {
            refreshing: false,
            registration: null,
            updateExists: false,
        };
    },
    emits: ["update-available"],
    created() {
        document.addEventListener("swUpdated", this.showRefreshUI, {
            once: true,
        });

        navigator.serviceWorker.addEventListener("controllerchange", () => {
            window.console.log("Refresh");
            if (this.refreshing) return;
            this.refreshing = true;
            window.console.log("reloading");
            window.location.reload();
        });
    },
    methods: {
        showRefreshUI(e) {
            this.registration = e.detail;
            this.updateExists = true;
            this.$emit("update-available");
        },
        refreshApp() {
            // this.updateExists = false;
            if (!this.registration || !this.registration.waiting) {
                return;
            }
            this.registration.waiting.postMessage("skipWaiting");
        },
    },
};
</script>

<style scoped>
.new-version-pop-up {
    position: absolute;
    top: 4rem;
    right: 10px;
    width: 10rem;
    text-align: center;
    background-color: whitesmoke;
    padding: 0.5rem;
    border: 1px solid gray;
    border-radius: 1rem;
}

.btn {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
}
</style>