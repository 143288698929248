<template>
    <VueDatePicker
        v-model="value"
        :format="format"
        year-first
        auto-apply
        :enable-time-picker="false"
        :week-start="1"
        locale="es"
    />
</template>

<script>
import VueDatePicker from "@vuepic/vue-datepicker";
export default {
    name: "date-picker",
    components: {
        VueDatePicker,
    },
    props: {
        date: {
            type: Date,
            default: null,
        },
    },
    emits: ["update:date"],
    data() {
        return {
            value: null,
        };
    },
    mounted() {
        this.value = this.date;
    },
    watch: {
        date() {
            this.value = this.date;
        },
        value() {
            this.$emit("update:date", this.value);
        },
    },
    methods: {
        format(date) {
            const day = date.getDate().toString().padStart(2, "0");
            const month = (date.getMonth() + 1).toString().padStart(2, "0");
            const year = date.getFullYear();

            return `${day}/${month}/${year}`;
        },
    },
};
</script>