import Repository from "./repository"

const resource = "club";

export default {
    async get() {
        let endpoint = `${resource}`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async save(payload) {
        let endpoint = `${resource}`;
        let response = await Repository.post(endpoint, payload);
        return response;
    }
}