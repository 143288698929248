<template>
    <div class="nav-item dropdown me-2">
        <div
            class="nav-link"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
        >
            <div class="active-language">
                <span v-if="selected" :class="`fi fi-${selected.icon}`" />
            </div>
        </div>
        <div class="dropdown-menu dropdown-menu-end background-gray">
            <template v-for="language in languages" :key="language.key">
                <div
                    class="language"
                    :class="{ active: isActive(language.key) }"
                    @click="change(language.key)"
                >
                    <span :class="`fi fi-${language.icon}`" />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
const localeKey = "scoutyoupro:lang";

var defaultLang =
    process.env.VUE_APP_DEFAULT_LANGUAGE !== ""
        ? process.env.VUE_APP_DEFAULT_LANGUAGE
        : "es";

export default {
    name: "language-select",
    data() {
        return {
            selected: null,
            languages: [
                { key: "es", icon: "es" },
                { key: "en", icon: "gb" },
                { key: "es-co", icon: "co" },
            ],
        };
    },
    mounted() {
        const locale = this.$i18n.locale;

        let lang = localStorage[localeKey] ?? defaultLang;
        if (lang !== undefined && lang !== locale) {
            this.setLanguage(lang);
        }

        this.selected = this.languages.find((l) => l.key === lang);
    },
    methods: {
        setLanguage(lang) {
            this.$i18n.locale = lang;
            localStorage[localeKey] = lang;
        },
        isActive(lang) {
            return this.$i18n.locale === lang;
        },
        change(lang) {
            if (this.$i18n.locale !== lang) {
                this.setLanguage(lang);
                this.$router.go();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";
.active-language {
    .fi {
        cursor: pointer;
        margin-top: 0.25rem;
        font-size: 1.75em;
        border-radius: 3px;
    }
}

.dropdown-menu {
    position: absolute;
    margin-top: 0;
    padding: 0.5rem;

    text-align: center;

    .language {
        cursor: pointer;
        display: inline-block;
        width: fit-content;

        padding: 0.25rem;
        margin: 0.1rem;

        border-radius: 5px;
        border: 3px solid transparent;

        &:not(.active) {
            &:hover {
                border-color: $secondary;
                background-color: $secondary;
            }
        }

        &.active {
            border-color: $primary;
            background-color: $primary;
        }

        .fi {
            font-size: 2em;
            vertical-align: middle;
            border-radius: 3px;
        }
    }
}
</style>