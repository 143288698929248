<template>
    <div
        class="modal fade"
        id="add-player-to-record-modal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
    >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header primary">
                    <h5 class="modal-title fs-5">
                        {{ $t("player.add") }}
                    </h5>
                    <button class="btn-close" @click="close" />
                </div>

                <div class="modal-body">
                    <div v-show="saving">
                        <Loading />
                        <h6 class="text-primary text-center">
                            {{ $t("common.saving") }}
                        </h6>
                    </div>
                    <div v-show="!saving">
                        <PagedRecords
                            ref="records"
                            v-model:page="currentPage"
                            :items="getMyRecords"
                            :take="take"
                            :total="total"
                            :isLoading="isLoading"
                            :showCreate="false"
                            :loadOnStart="false"
                            @click="addToRecord"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from "bootstrap";
import { RepositoryFactory } from "@/services/api";
import { useRecordsStore } from "@/stores/records";

import PagedRecords from "@/components/records/records-lists/types/base/PagedRecords.vue";

export default {
    name: "add-player-to-record",
    components: {
        PagedRecords,
    },
    data() {
        return {
            modal: null,
            isLoading: false,
            saving: false,

            currentPage: 1,
            take: 4,
            total: 0,

            playerId: null,
            recordStore: useRecordsStore(),
        };
    },
    mounted() {
        this.modal = new Modal("#add-player-to-record-modal");
    },
    beforeUnmount() {
        this.close();
    },
    watch: {
        "recordStore.getPlayerId"(playerId) {
            this.playerId = playerId;

            if (playerId !== null) {
                this.open();
            }
        },
    },
    methods: {
        async addToRecord(record) {
            this.saving = true;
            try {
                let payload = {
                    recordId: record.id,
                    playerId: this.playerId,
                };

                await RepositoryFactory.record.addPlayer(payload);
                this.$notify.success("Jugador añadido!");
            } catch {
                this.$notify.error("Error añadiendo jugador a la lista");
            }
            this.saving = false;
        },
        async getMyRecords(context) {
            this.isLoading = true;
            try {
                let response = await RepositoryFactory.record.getCreatedPaged({
                    page: context.page,
                    take: context.take,
                    search: context.search,
                });

                this.total = response.data.total;
                return response.data.items;
            } catch (error) {
                window.console.log(error);
            } finally {
                this.isLoading = false;
            }
            return [];
        },
        open() {
            this.$refs.records.refresh();
            this.modal.show();
        },
        close() {
            this.modal.hide();
            this.recordStore.setPlayerId(null);
        },
    },
};
</script>

<style lang="scss" scoped>
.modal-dialog{
    max-width: 40rem;
}
</style>