export const orientations = [
    { id: 0, key: "offensive" },
    { id: 1, key: "offensive-defensive" },
    { id: 2, key: "defensive-offensive" },
    { id: 3, key: "defensive" },
    { id: 4, key: "offensive-inside" },
    { id: 5, key: "offensive-out" },
]

export function getOrientation(id) {
    let ori = orientations.find(d => d.id === id);
    return ori;
}