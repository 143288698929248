import Repository from "./repository"

const resource = "metric"

export default {
    async getSummary({ category }) {
        let endpoint = `${resource}/summary/${category}`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getGameCicleRatios() {
        let endpoint = `${resource}/game-cicle-ratios`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getProfiles({ category }) {
        let endpoint = `${resource}/profiles/${category}`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getPositionQuestions({ category, position }) {
        let endpoint = `${resource}/${category}/${position}/questions`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getGroupedPositionQuestions({ category }) {
        let endpoint = `${resource}/grouped-position-questions/${category}`;
        let response = await Repository.get(endpoint);
        return response;
    }
}