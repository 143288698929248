import { useIdentityStore } from "@/stores/identity";

let _store = null;
var store = () => _store ?? loadStore();

function loadStore() {
    _store = useIdentityStore();
    return _store;
}

function allowViewLevel(level) { return store().allowViewLevel(level); }
function getViewLevels() { return store().getViewLevels; }

function allowClub() { return store().allowClub; }
function allowUsers() { return store().allowUsers; }
function allowSearch() { return store().allowSearch; }
function allowMatches() { return store().isAdmin || store().isSuperAdmin; }
function allowRecords() { return store().allowRecords; }
function allowPlayerAverage() { return store().allowPlayerAverage; }
function allowPlayerTransference() { return allowUpdate() && store().allowPlayerTransference; }
function allowScoutComparer() { return store().allowScoutComparer; }
function allowPlayerComparer() { return store().allowPlayerComparer; }
function allowHistorical() { return store().allowHistorical; }
function allowLineup() { return store().allowLineup; }
function allowTeamPreferences() { return store().allowTeamPreferences; }
function allowMetrics() { return store().isSuperAdmin; }

function allowCreate() { return store().allowCreate; }
function allowUpdate() { return store().allowUpdate; }
function allowDelete() { return store().allowDelete; }
function allowShare() { return store().allowShare; }

export const permissions = {
    allowViewLevel,
    getViewLevels,

    allowClub,
    allowUsers,
    allowSearch,
    allowMatches,
    allowRecords,
    allowPlayerAverage,
    allowPlayerTransference,
    allowPlayerComparer,
    allowScoutComparer,
    allowHistorical,
    allowLineup,
    allowTeamPreferences,
    allowMetrics,

    allowCreate,
    allowUpdate,
    allowDelete,
    allowShare
};
