<template>
    <component :is="layout">
        <router-view />
    </component>
</template>

<script>
const defaultLayout = "default";

export default {
    name: "App",
    computed: {
        layout() {
            if (this.$route.name === undefined) return null;

            let layout = this.$route.meta.layout || defaultLayout;
            return layout + "-layout";
        },
    },
};
</script>
