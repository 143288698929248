import { defineStore } from 'pinia'

const basic = 'basic';
const premium = 'premium';
const ratingTypeKey = "scoutyoupro:rating_type";

export const usePreferencesStore = defineStore('preferences', {
    state: () => ({
        level: 'n0',
        ratingType: localStorage[ratingTypeKey] ?? basic
    }),
    getters: {
        getLevel: (state) => state.level,
        isBasic: (state) => state.ratingType === basic,
        isPremium: (state) => state.ratingType === premium,
        getMode: (state) => state.ratingType === basic ? 0 : 1
    },
    actions: {
        setLevel(level) {
            this.level = level;
        },
        reset() {
            this.level = 'n0'
        },
        setBasic() {
            this.ratingType = basic;
            localStorage[ratingTypeKey] = this.ratingType;
            location.reload();
        },
        setPremium() {
            this.ratingType = premium;
            localStorage[ratingTypeKey] = this.ratingType;
            location.reload();
        },
    }
})