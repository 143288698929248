import { useIdentityStore } from "@/stores/identity";

let identityStore = null;

export function getHeaders() {
    if (identityStore === null) identityStore = useIdentityStore();

    let access_token = identityStore.getAccesToken;
    if (access_token !== null && access_token !== undefined) {
        return {
            'Authorization': `Bearer ${access_token}`
        };
    }
    return {};
}