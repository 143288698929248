<template>
    <div class="centered-content">
        <div class="custom-check" :class="{ active: active }" @click="activate">
            {{ label }}
        </div>
    </div>
</template>

<script>
export default {
    name: "custom-check",
    props: {
        checked: {
            type: Boolean,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
    },
    emits: ["update:checked"],
    data() {
        return {
            active: false,
        };
    },
    methods: {
        activate() {
            this.active = !this.active;
            this.$emit("update:checked", this.active);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";

.custom-check {
    text-align: center;
    cursor: pointer;
    width: 12rem;

    border: 3px solid $primary;
    border-radius: 10px;

    padding: 0.5rem;

    &:hover {
        box-shadow: 0rem 0rem 0.5rem $primary;
    }

    &.active {
        box-shadow: 0rem 0rem 0.25rem $primary;
        background-color: $secondary-light;
    }
}
</style>