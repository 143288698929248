<template>
    <div class="full-screen-container">
        <slot />
    </div>
</template>

<script>
export default {
    name: "full-screen-layout",
};
</script>

<style scoped>
.full-screen-container {
    height: 100vh;
    width: 100vw;

    overflow-x: hidden;
}
</style>