import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { i18n } from './i18n';
import { helpers } from "./services/helpers/functions.js";
import { parser } from "./services/helpers/parser.js";
import { permissions } from "./services/identity/permissions";
import Icons from "./icons";

import './registerServiceWorker';

import "vue-toastification/dist/index.css";
import "vue-multiselect/dist/vue-multiselect.css";
import '@vuepic/vue-datepicker/dist/main.css';
import "./assets/scss/index.scss";
import 'bootstrap'; // Import Bootstrap JS utilities
import "/node_modules/flag-icons/css/flag-icons.min.css";

import App from './App.vue';
import router from './router';
import DefaultLayout from "./layout/Default.vue";
import FullScreenLayout from "./layout/FullScreen.vue";
import Icon from "@/components/common/Icon";
import CustomIcon from "@/components/common/CustomIcon.vue";
import Loading from "@/components/common/Loading.vue";
import Logo from "@/components/common/Logo.vue";
import LogoPlaceholder from "@/components/common/LogoPlaceholder.vue";
import UserLogo from "@/components/common/UserLogo.vue"
import Switch from "@/components/common/Switch.vue";
import Check from "@/components/common/Check.vue";
import ScoutyouTable from "@/components/widgets/table/Table.vue";
import CountryFlag from "@/components/common/CountryFlag.vue";
import Rating from "@/components/common/Rating.vue";

// Inputs
import VueMultiselect from 'vue-multiselect'
import TextInput from "@/components/widgets/inputs/TextInput.vue";
import ImageInput from "@/components/widgets/inputs/ImageInput.vue";
import FileInput from "@/components/widgets/inputs/FileInput.vue";
import NumberInput from "@/components/widgets/inputs/NumberInput.vue";
import DatePicker from "@/components/widgets/inputs/DatePicker.vue";
import DualRange from "@/components/widgets/inputs/DualRange.vue";

import VChart from "vue-echarts";
import Toast, { useToast } from "vue-toastification";

const pinia = createPinia();
const app = createApp(App);

app.component("Icon", Icon);
app.component("CustomIcon", CustomIcon);
app.component("Loading", Loading);
app.component("Logo", Logo);
app.component("LogoPlaceholder", LogoPlaceholder);
app.component("UserLogo", UserLogo);
app.component("CustomSwitch", Switch);
app.component("CustomCheck", Check);
app.component("ScoutyouTable", ScoutyouTable);
app.component("CountryFlag", CountryFlag);
app.component("Rating", Rating);

app.component("VSelect", VueMultiselect);
app.component("TextInput", TextInput);
app.component("ImageInput", ImageInput);
app.component("FileInput", FileInput);
app.component("NumberInput", NumberInput);
app.component("DatePicker", DatePicker);
app.component("DualRange", DualRange);

app.component("default-layout", DefaultLayout);
app.component("full-screen-layout", FullScreenLayout);

app.component("v-chart", VChart);

let toastOptions = { timeout: 7000 };
app.use(Toast, toastOptions);
app.config.globalProperties.$notify = useToast(); // Magix to implement global $toast

app.config.globalProperties.$helper = helpers;
app.config.globalProperties.$parser = parser;
app.config.globalProperties.$permissions = permissions;

app.use(router);
app.use(pinia);
app.use(i18n);

Icons.load(app);

app.mount('#app');