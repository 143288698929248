<template>
    <img
        :width="width"
        :height="height"
        :src="require(`@/assets/images/icons/${name}.png`)"
        :class="[{ inverted: inverted }, helperClass]"
    />
</template>

<script>
export default {
    name: "custom-icon",
    props: {
        name: {
            type: String,
            required: true,
        },
        inverted: {
            type: Boolean,
            default: true,
        },
        width: {
            type: Number,
            default: 32,
        },
        height: {
            type: Number,
            default: 32,
        },
        helperClass: {
            type: String,
            default: "",
        },
    },
};
</script>

<style lang="scss" scoped>

.inverted {
    filter: invert(84%) sepia(1%) saturate(0%) hue-rotate(325deg)
        brightness(89%) contrast(85%);
}
</style>