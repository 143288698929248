<template>
    <div class="row">
        <div class="col-12 my-2 px-4">
            <VueSlider
                v-model="value"
                :min="min"
                :max="max"
                :interval="step"
                :lazy="false"
            />
        </div>
        <div class="col-6">
            <div class="form-floating mb-2">
                <input
                    class="form-control"
                    v-model="minValue"
                    :min="min"
                    :max="max"
                    :step="step"
                    type="number"
                />
                <label class="text-primary">
                    {{ $t("common.min") }}
                </label>
            </div>
        </div>
        <div class="col-6">
            <div class="form-floating mb-2">
                <input
                    class="form-control"
                    v-model="maxValue"
                    :min="min"
                    :max="max"
                    :step="step"
                    type="number"
                />
                <label class="text-primary">
                    {{ $t("common.max") }}
                </label>
            </div>
        </div>
    </div>
</template>

<script>
import VueSlider from "vue-3-slider-component";

export default {
    name: "dual-range",
    components: {
        VueSlider,
    },
    props: {
        range: {
            type: Array,
            default: null,
        },
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 5,
        },
        step: {
            type: Number,
            default: 0.1,
        },
    },
    emits: ["update:range"],
    data() {
        return {
            minValue: null,
            maxValue: null,
            value: [0, 5],
        };
    },
    mounted() {
        if (this.range !== null) {
            this.value = this.range;
        } else {
            this.value = [this.min, this.max];
        }

        this.minValue = this.value[0];
        this.maxValue = this.value[1];
    },
    computed: {
        left() {
            return this.value[0];
        },
        right() {
            return this.value[1];
        },
    },
    watch: {
        range() {
            if (this.range !== null) this.value = this.range;
        },
        minValue() {
            this.value = [this.minValue, this.maxValue];
        },
        maxValue() {
            this.value = [this.minValue, this.maxValue];
        },
        value() {
            this.minValue = this.value[0];
            this.maxValue = this.value[1];
            this.$emit("update:range", this.value);
        },
    },
};
</script>

<style lang="scss" scoped>
</style>