<template>
    <nav class="navbar sticky-top">
        <div class="container-fluid">
            <div
                class="magic-col"
                :class="{ 'with-new-version': newVersionAvailable }"
            >
                <Breadcrumb />
            </div>
            <div class="navbar-end">
                <NewVersionPopUp
                    @update-available="newVersionAvailable = true"
                />
                <LanguageSelect />
                <NavbarSettings />
                <UserInfo />
            </div>
        </div>
    </nav>
</template>

<script>
import UserInfo from "./UserInfo.vue";
import NavbarSettings from "./NavbarSettings.vue";
import Breadcrumb from "@/components/widgets/Breadcrumb.vue";
import LanguageSelect from "@/components/widgets/LanguageSelect.vue";
import NewVersionPopUp from "./NewVersionPopUp.vue";

export default {
    name: "horizontal-navbar",
    components: {
        UserInfo,
        NavbarSettings,
        Breadcrumb,
        LanguageSelect,
        NewVersionPopUp,
    },
    data() {
        return {
            newVersionAvailable: false,
        };
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";

.navbar {
    padding: 0;
    background-color: $navbar-background;
    color: $navbar-text-color !important;
    height: 55px;

    @media only screen and (min-width: 768px) {
        border-left: 1px solid #696969;
    }
}

.magic-col {
    flex: 0 0 auto;
    width: calc(100% - 140px);

    &.with-new-version {
        width: calc(100% - 230px);
    }
}

.navbar-end {
    display: flex;
}
</style>