<template>
    <div class="text-input">
        <label class="fw-bold">{{ label }}</label>
        <input
            v-model="value"
            type="number"
            :min="min"
            :max="max"
            class="form-control"
            @input="update"
        />
    </div>
</template>

<script>
export default {
    name: "number-input",
    props: {
        number: {
            type: Number,
            default: null,
        },
        label: {
            type: String,
            default: "",
        },
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: null,
        },
    },
    emits: ["update:number"],
    data() {
        return {
            value: null,
        };
    },
    mounted() {
        this.value = this.number;
    },
    watch: {
        number() {
            this.value = this.number;
        },
    },
    methods: {
        update() {
            let value = this.value;
            if (value === "") value = null;
            this.$emit("update:number", value);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";

.text-input {
    margin-bottom: 0.5rem;

    input {
        margin-top: 0.25rem;

        padding-top: 7px;
        padding-bottom: 7px;

        &:focus {
            border: 1px solid $primary;
            box-shadow: none;
        }
    }

    label {
        font-weight: bold;
    }
}
</style>