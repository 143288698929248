import { usePreferencesStore } from "@/stores/preferences";
import { permissions } from "@/services/identity/permissions";

let _store = null;
var store = () => _store ?? loadStore();

function loadStore() {
    _store = usePreferencesStore();
    return _store;
}

let rating_key = () => store().isPremium ? 'premiumRating' : 'basicRating';
let complete_key = () => store().isPremium ? 'premiumComplete' : 'basicComplete';

export function getRoundedScore(value) {
    if (value > 4.40) { return 5 }
    if (value > 3.70) { return 4 }
    if (value > 2.90) { return 3 }
    if (value > 1.90) { return 2 }
    if (value >= 1.00) { return 1 }
    return 0;
}

export function getColorClass(value, complete) {
    if (complete === false && value > 0) {
        return "scout-gray"
    }
    if (value > 4.40) { return "scout-green" }
    if (value > 3.70) { return "scout-green-light" }
    if (value > 2.90) { return "scout-yellow" }
    if (value > 1.90) { return "scout-orange" }
    if (value >= 1.00) { return "scout-red" }
    return "scout-white";
}

export function getRoundedScoreToDecimals(value) {
    return Math.round(value * 100) / 100;
}

function parseRating(key, rating, complete, level, section) {
    if (complete === undefined) complete = rating > 0;
    let rounded = getRoundedScoreToDecimals(rating);
    let color = getColorClass(rounded, complete);
    return {
        key: key,
        rating: rounded,
        class: color,
        level: level,
        section: section
    };
}

function parseProfile(scoutProfile, level, section) {
    let profileRating = getRoundedScoreToDecimals(scoutProfile.rating);
    let profile = {
        key: scoutProfile.key,
        level: level,
        rating: profileRating,
        complete: scoutProfile.complete,
        class: getColorClass(profileRating, scoutProfile.complete),
        ratings: [],
        section: section
    };

    for (const question of scoutProfile.questions) {
        profile.ratings.push(parseRating(question.key, question.rating, question.rating > 0, level, section));
    }

    return profile;
}

function parseProfileGroup(scoutProfileGroup, level, section) {
    var profileGroupRating = getRoundedScoreToDecimals(scoutProfileGroup.rating);
    var profileGroup = {
        rating: profileGroupRating,
        complete: scoutProfileGroup.complete,
        class: getColorClass(profileGroupRating, scoutProfileGroup.complete),
        profiles: [],
        section: section
    };

    for (const profile of scoutProfileGroup.profiles) {
        profileGroup.profiles.push(parseRating(profile.key, profile.rating, profile.complete, level, section));
    }

    return profileGroup;
}

function parseProfileGroupWithQuestions(scoutProfileGroup, level, section) {
    var profileGroupRating = getRoundedScoreToDecimals(scoutProfileGroup.rating);
    var profileGroup = {
        rating: profileGroupRating,
        complete: scoutProfileGroup.complete,
        class: getColorClass(profileGroupRating, scoutProfileGroup.complete),
        profiles: [],
        section: section
    };

    for (const profile of scoutProfileGroup.profiles) {
        profileGroup.profiles.push(parseProfile(profile, level, profile.key));
    }

    return profileGroup;
}

function parseN0(level) {
    let levelRating = getRoundedScoreToDecimals(level.rating);
    let n0 = {
        key: 'n0',
        rating: levelRating,
        leagueLevel: level.leagueLevel,
        level: 'n0',
        complete: levelRating > 0,
        class: getColorClass(levelRating, level.complete)
    };
    return n0;
}

function parseN1(level) {
    let n1 = {
        rating: getRoundedScoreToDecimals(level.rating),
        complete: level.complete,
        gameCicle: {
            rating: getRoundedScoreToDecimals(level.gameCicle.rating),
            complete: level.gameCicle.complete,
            ratings: []
        },
        abp: {
            ratings: []
        }
    };

    n1.class = getColorClass(n1.rating, n1.complete);
    n1.gameCicle.class = getColorClass(n1.gameCicle.rating, n1.gameCicle.complete);

    n1.gameCicle.ratings.push(parseRating('attack', level.gameCicle.attack, level.gameCicle.attack > 0, 'n1', 'game-cicle'));
    n1.gameCicle.ratings.push(parseRating('atk-def-transition', level.gameCicle.atkDefTransition, level.gameCicle.atkDefTransition > 0, 'n1', 'game-cicle'));
    n1.gameCicle.ratings.push(parseRating('defense', level.gameCicle.defense, level.gameCicle.defense > 0, 'n1', 'game-cicle'));
    n1.gameCicle.ratings.push(parseRating('def-atk-transition', level.gameCicle.defAtkTransition, level.gameCicle.defAtkTransition, 'n1', 'game-cicle'));

    n1.abp.ratings.push(parseRating('abp-of', level.abpOf, level.abpOf > 0, 'n1', 'abp'));
    n1.abp.ratings.push(parseRating('abp-def', level.abpDef, level.abpOf > 0, 'n1', 'abp'));

    return n1;
}

function parseN2(level, isBasic) {
    let n2 = {
        rating: getRoundedScoreToDecimals(level.rating),
        complete: level.complete,
        isBasic: store().isBasic || isBasic
    };

    n2.specificPosition = parseProfile(level.specificPosition, 'n2', 'specific-position');

    n2.general = store().isBasic || isBasic
        ? parseProfileGroup(level.generalProfile, 'n2', 'general-profiles')
        : parseProfileGroupWithQuestions(level.generalProfile, 'n2', 'general-profiles');

    n2.class = getColorClass(n2.rating, n2.complete);

    return n2;
}

function parseN3(level) {
    let n3 = {
        rating: getRoundedScoreToDecimals(level.rating),
        complete: level.complete,
        performance: parseProfile(level.performanceProfile, 'n3', 'performance-profile')
    };

    n3.class = getColorClass(n3.rating, n3.complete);

    return n3;
}

function parseN4(level) {
    let n4 = {
        rating: getRoundedScoreToDecimals(level.rating),
        complete: level.complete,
        isBasic: store().isBasic,
    };

    n4.socioPersonal = store().isBasic
        ? parseProfileGroup(level.socioPersonalProfile, 'n4', 'socio-personal-profile')
        : parseProfileGroupWithQuestions(level.socioPersonalProfile, 'n4', 'socio-personal-profile');
    n4.class = getColorClass(n4.rating, n4.complete);

    return n4;
}

function parseN5(level) {
    let n5 = {
        rating: getRoundedScoreToDecimals(level.rating),
        complete: level.complete,
        isBasic: store().isBasic,
    };

    n5.economic = store().isBasic
        ? parseRating(level.economicProfile.key, level.economicProfile.rating, level.economicProfile.complete, 'n5', 'economic-profile')
        : parseProfile(level.economicProfile, 'n5', 'economic');
    n5.economic.key = "economic";
    n5.class = getColorClass(n5.rating, n5.complete);

    return n5;
}

function parseSummaryLevels(levels) {
    let n0 = parseRating('n0', levels.n0.rating, levels.n0.complete, 'n0');
    n0.leagueLevel = levels.n0.leagueLevel;

    let summary = []

    summary.push(n0);
    if (levels.n1) summary.push(parseRating('n1', levels.n1.rating, levels.n1.complete, 'n1'));
    if (levels.n2) summary.push(parseRating('n2', levels.n2.rating, levels.n2.complete, 'n2'));
    if (levels.n3) summary.push(parseRating('n3', levels.n3.rating, levels.n3.complete, 'n3'));
    if (levels.n4) summary.push(parseRating('n4', levels.n4.rating, levels.n4.complete, 'n4'));
    if (levels.n5) summary.push(parseRating('n5', levels.n5.rating, levels.n5.complete, 'n5'));

    return summary;
}

export function parseSummaryProfiles(levels) {
    try {
        let summary = [];

        if (levels.n1) {
            let abp = levels.n1.abp.ratings;
            summary.push(parseRating('game-cicle', levels.n1.gameCicle.rating, levels.n1.gameCicle.complete, 'n1'));
            summary.push(parseRating('abp-of', abp[0].rating, abp[0].rating > 0, 'n1'));
            summary.push(parseRating('abp-def', abp[1].rating, abp[1].rating > 0, 'n1'));
        }

        if (levels.n2) {
            summary.push(parseRating('general', levels.n2.general.rating, levels.n2.general.complete, 'n2'));
            summary.push(parseRating('specific-position', levels.n2.specificPosition.rating, levels.n2.specificPosition.complete, 'n2'));
        }

        if (levels.n3) summary.push(parseRating('performance', levels.n3.performance.rating, levels.n3.performance.complete, 'n3'));
        if (levels.n4) summary.push(parseRating('socio-personal', levels.n4.socioPersonal.rating, levels.n4.socioPersonal.complete, 'n4'));
        if (levels.n5) summary.push(parseRating('economic', levels.n5.economic.rating, levels.n5.economic.complete, 'n5'));

        return summary;
    } catch {
        return null;
    }
}

export function parseMatchLevels(levels) {
    let ratings = {};

    if (levels.n0) ratings.n0 = parseN0(levels.n0);
    if (levels.n1) ratings.n1 = parseN1(levels.n1);
    if (levels.n2) ratings.n2 = parseN2(levels.n2, true);

    return ratings;
}

export function parseScoutLevels(levels) {
    let ratings = {};

    if (levels.n0) ratings.n0 = parseN0(levels.n0);
    if (levels.n1 && permissions.allowViewLevel('n1')) ratings.n1 = parseN1(levels.n1);
    if (levels.n2 && permissions.allowViewLevel('n2')) ratings.n2 = parseN2(levels.n2);
    if (levels.n3 && permissions.allowViewLevel('n3')) ratings.n3 = parseN3(levels.n3);
    if (levels.n4 && permissions.allowViewLevel('n4')) ratings.n4 = parseN4(levels.n4);
    if (levels.n5 && permissions.allowViewLevel('n5')) ratings.n5 = parseN5(levels.n5);

    return ratings;
}

export function parseScout(scout) {
    let ratings = parseScoutLevels(scout.levels);

    if (ratings.n1) ratings.n1.preferencialTendencies = scout.preferencialTendencies;

    return {
        id: scout.id,
        playerId: scout.playerId,
        position: scout.position,
        summary: {
            levels: parseSummaryLevels(ratings),
            profiles: parseSummaryProfiles(ratings)
        },
        descriptors: scout.descriptors,
        knowledge: scout.playerKnowledge,
        observations: scout.observations,
        levels: ratings
    };
}

function parseGlobal(rating, key, level) {
    return {
        key: key,
        level: level ?? key,
        rating: rating.rating,
        complete: rating.complete,
        class: rating.class
    }
}

function parseScoutGlobal(ratings) {
    let global = [];
    const { n1, n2, n3, n4, n5 } = ratings;

    if (n1) {
        let level = parseGlobal(n1, 'n1');
        level.items = [parseGlobal(n1.gameCicle, 'game-cicle', 'n1'), ...n1.abp.ratings]
        global.push(level);
    }

    if (n2) {
        let level = parseGlobal(n2, 'n2');
        level.items = [...n2.general.profiles, parseGlobal(n2.specificPosition, 'specific-position', 'n2')];
        global.push(level);
    }

    if (n3) {
        let level = parseGlobal(n3, 'n3');
        level.items = [...n3.performance.ratings];
        global.push(level);
    }

    if (n4) {
        let level = parseGlobal(n4, 'n4');
        level.items = [...n4.socioPersonal.profiles];
        global.push(level);
    }

    if (n5) {
        let level = parseGlobal(n5, 'n5');
        level.items = [n5.economic];
        global.push(level);
    }

    return global;
}

function parseAverageScoutKnowledge(knowledge) {
    knowledge.average = getRoundedScoreToDecimals(knowledge.average);
    knowledge.class = getColorClass(knowledge.average, knowledge.average > 0);

    return knowledge;
}

export function parseAverageScout(scout) {
    let ratings = parseScoutLevels(scout.levels);

    if (ratings.n1) ratings.n1.averagePreferencialTendencies = scout.preferencialTendencies;

    return {
        playerId: scout.playerId,
        position: scout.position,
        level: ratings.n0.leagueLevel,
        levels: ratings,
        global: parseScoutGlobal(ratings),
        summary: {
            levels: parseSummaryLevels(ratings),
            profiles: parseSummaryProfiles(ratings)
        },
        descriptors: scout.descriptors,
        knowledge: parseAverageScoutKnowledge(scout.averagePlayerKnowledge),
        observations: scout.observations,
        scouters: scout.scouts
    };
}

export function parseLevels(levels) {
    let response = {};

    if (levels.n0) response.n0 = parseN0(levels.n0);
    if (levels.n1) response.n1 = parseRating('n1', levels.n1.rating, levels.n1.complete);
    if (levels.n2) response.n2 = parseRating('n2', levels.n2.rating, levels.n2.complete);
    if (levels.n3) response.n3 = parseRating('n3', levels.n3.rating, levels.n3.complete);
    if (levels.n4) response.n4 = parseRating('n4', levels.n4.rating, levels.n4.complete);
    if (levels.n5) response.n5 = parseRating('n5', levels.n5.rating, levels.n5.complete);

    return response;
}

function getLevelRating(level, key) {
    let ratingKey = rating_key();
    let completeKey = complete_key();
    return {
        key: key,
        rating: getRoundedScoreToDecimals(level[ratingKey]),
        complete: level[completeKey],
        class: getColorClass(level[ratingKey], level[completeKey])
    }
}

export function parseScoutRatings(scout) {
    scout.n0.rating = getRoundedScoreToDecimals(scout.n0.rating);
    scout.n0.class = getColorClass(scout.n0.rating);
    scout.n0.key = 'n0';

    if (scout.n1 && permissions.allowViewLevel('n1')) {
        scout.n1.rating = getRoundedScoreToDecimals(scout.n1.rating);
        scout.n1.class = getColorClass(scout.n1.rating, scout.n1.complete);
        scout.n1.key = 'n1';
    }

    if (scout.n2 && permissions.allowViewLevel('n2')) scout.n2 = getLevelRating(scout.n2, 'n2');
    if (scout.n3 && permissions.allowViewLevel('n3')) scout.n3 = getLevelRating(scout.n3, 'n3');
    if (scout.n4 && permissions.allowViewLevel('n4')) scout.n4 = getLevelRating(scout.n4, 'n4');
    if (scout.n5 && permissions.allowViewLevel('n5')) scout.n5 = getLevelRating(scout.n5, 'n5');
}

export function parseAveragePlayerPositionRatings(player) {
    player.ratings = player.positionRatings;
    delete player.positionRatings;

    for (const positionRating of player.ratings) {
        parseScoutRatings(positionRating);
    }

    player.first = player.ratings.find(r => r.position === player.position);
    player.second = player.ratings.find(r => r.position === player.secondPosition) ?? null;
}

export function parsePlayerPositionRatings(player) {
    player.ratings = player.positionRatings;
    delete player.positionRatings;

    for (const positionRating of player.ratings) {
        parseScoutRatings(positionRating);
    }

    player.first = player.ratings.find(r => r.position === player.position) ?? getDefaultScout(player.team.league.level);
    player.second = player.ratings.find(r => r.position === player.secondPosition) ?? null;
}

function getDefaultScout(level) {
    return {
        "position": 16,
        "n0": { "key": "n0", "rating": 0, "leagueLevel": level, "class": getColorClass(0) },
        "n1": { "key": "n1", "rating": 0, "complete": false, "class": getColorClass(0) },
        "n2": { "key": "n2", "rating": 0, "complete": false, "class": getColorClass(0) },
        "n3": { "key": "n3", "rating": 0, "complete": false, "class": getColorClass(0) },
        "n4": { "key": "n4", "rating": 0, "complete": false, "class": getColorClass(0) },
        "n5": { "key": "n5", "rating": 0, "complete": false, "class": getColorClass(0) },
        "lastModifiedAt": null
    }
}