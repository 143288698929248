export const countries = [
    "afghanistan", "albania", "algeria", "andorra", "angola", "antigua-and-barbuda", "argentina",
    "armenia", "australia", "austria", "azerbaijan", "bahamas", "bahrain", "bangladesh", "barbados",
    "belarus", "belgium", "belize", "benin", "bermudas", "bhutan", "bolivia", "bosnia-and-herzegovina", "botswana",
    "brazil", "brunei", "bulgaria", "burkina-faso", "burundi", "cote-divoire", "cabo-verde", "cambodia",
    "cameroon", "canada", "central-african-republic", "chad", "chile", "china", "chinese-taipie", "colombia", "comoros",
    "congo", "cook-islands", "costa-rica", "croatia", "cuba", "cyprus", "czechia", "democratic-republic-of-the-congo",
    "denmark", "djibouti", "dominica", "dominican-republic", "ecuador", "egypt", "el-salvador", "england",
    "equatorial-guinea", "eritrea", "estonia", "eswatini", "ethiopia", "feroa-island", "fiji", "finland", "france",
    "gabon", "gambia", "georgia", "germany", "ghana", "greece", "grenada", "guatemala", "guinea",
    "guinea-bissau", "guyana", "haiti", "holy-see", "honduras", "hong-kong", "hungary", "iceland", "india",
    "indonesia", "iran", "iraq", "ireland", "israel", "italy", "jamaica", "japan", "jordan",
    "kazakhstan", "kenya", "kiribati", "kosovo", "kuwait", "kyrgyzstan", "laos", "latvia", "lebanon", "lesotho",
    "liberia", "libya", "liechtenstein", "lithuania", "luxembourg", "madagascar", "malawi", "malaysia",
    "maldives", "mali", "malta", "marshall-islands", "mauritania", "mauritius", "mexico", "micronesia",
    "moldova", "monaco", "mongolia", "montserrat", "montenegro", "morocco", "mozambique", "myanmar", "namibia",
    "nauru", "nepal", "netherlands", "new-caledonia", "new-zealand", "nicaragua", "niger", "nigeria", "noth-ireland", "north-korea",
    "north-macedonia", "norway", "oman", "pakistan", "palau", "palestine-state", "panama",
    "papua-new-guinea", "paraguay", "peru", "philippines", "poland", "portugal", "puerto-rico", "qatar",
    "romania", "russia", "rwanda", "saint-kitts-and-nevis", "saint-lucia",
    "saint-vincent-and-the-grenadines", "samoa", "san-marino", "sao-tome-and-principe",
    "saudi-arabia", "scotland", "senegal", "serbia", "seychelles", "sierra-leone", "singapore", "slovakia",
    "slovenia", "solomon-islands", "somalia", "south-africa", "south-korea", "south-sudan",
    "spain", "sri-lanka", "sudan", "suriname", "sweden", "switzerland", "syria", "tajikistan",
    "tanzania", "tahiti", "thailand", "timor-leste", "togo", "tonga", "trinidad-and-tobago", "tunisia",
    "turkey", "turkmenistan", "tuvalu", "uganda", "ukraine", "united-arab-emirates",
    "united-kingdom", "united-states-of-america", "uruguay", "samoa-us", "uzbekistan", "vanuatu",
    "venezuela", "vietnam", "wales", "yemen", "yibuti", "zambia", "zimbabwe", "world-wide"
];

export const codes = {
    "afghanistan": "af", "albania": "al", "algeria": "dz", "andorra": "ad",
    "angola": "ao", "antigua-and-barbuda": "ag", "argentina": "ar", "armenia": "am",
    "australia": "au", "austria": "at", "azerbaijan": "az", "bahamas": "bs",
    "bahrain": "bh", "bangladesh": "bd", "barbados": "bb", "belarus": "by",
    "belgium": "be", "belize": "bz", "benin": "bj", "bermudas": "bm", "bhutan": "bt",
    "bolivia": "bo", "bosnia-and-herzegovina": "ba", "botswana": "bw", "brazil": "br",
    "brunei": "bn", "bulgaria": "bg", "burkina-faso": "bf", "burundi": "bi",
    "cote-divoire": "ci", "cabo-verde": "cv", "cambodia": "kh", "cameroon": "cm",
    "canada": "ca", "central-african-republic": "cf", "chad": "td", "chile": "cl",
    "china": "cn", "chinese-taipie": "tw", "colombia": "co", "comoros": "km",
    "congo": "cg", "cook-islands": "ck", "costa-rica": "cr", "croatia": "hr",
    "cuba": "cu", "cyprus": "cy", "czechia": "cz", "democratic-republic-of-the-congo": "cd",
    "denmark": "dk", "djibouti": "dj", "dominica": "dm", "dominican-republic": "do",
    "ecuador": "ec", "egypt": "eg", "el-salvador": "sv", "england": "gb-eng",
    "equatorial-guinea": "gq", "eritrea": "er", "estonia": "ee", "eswatini": "sz",
    "ethiopia": "et", "feroa-island": "fo", "fiji": "fj", "finland": "fi",
    "france": "fr", "gabon": "ga", "gambia": "gm", "georgia": "ge", "germany": "de",
    "ghana": "gh", "greece": "gr", "grenada": "gd", "guatemala": "gt",
    "guinea": "gn", "guinea-bissau": "gw", "guyana": "gy", "haiti": "ht",
    "holy-see": "va", "honduras": "hn", "hong-kong": "hk", "hungary": "hu",
    "iceland": "is", "india": "in", "indonesia": "id", "iran": "ir", "iraq": "iq",
    "ireland": "ie", "israel": "il", "italy": "it", "jamaica": "jm", "japan": "jp",
    "jordan": "jo", "kazakhstan": "kz", "kenya": "ke", "kiribati": "ki", "kosovo": "-",
    "kuwait": "kw", "kyrgyzstan": "kg", "laos": "la", "latvia": "lv", "lebanon": "lb",
    "lesotho": "ls", "liberia": "lr", "libya": "ly", "liechtenstein": "li",
    "lithuania": "lt", "luxembourg": "lu", "madagascar": "mg", "malawi": "mw",
    "malaysia": "my", "maldives": "mv", "mali": "ml", "malta": "mt", "marshall-islands": "mh",
    "mauritania": "mr", "mauritius": "mu", "mexico": "mx", "micronesia": "fm",
    "moldova": "md", "monaco": "mc", "mongolia": "mn", "montserrat": "ms",
    "montenegro": "me", "morocco": "ma", "mozambique": "mz", "myanmar": "mm",
    "namibia": "na", "nauru": "nr", "nepal": "np", "netherlands": "nl",
    "new-caledonia": "nc", "new-zealand": "nz", "nicaragua": "ni", "niger": "ne",
    "nigeria": "ng", "noth-ireland": "ie", "north-korea": "kr", "north-macedonia": "mk",
    "norway": "no", "oman": "om", "pakistan": "pk", "palau": "pw", "palestine-state": "ps",
    "panama": "pa", "papua-new-guinea": "pg", "paraguay": "py", "peru": "pe",
    "philippines": "ph", "poland": "pl", "portugal": "pt", "puerto-rico": "pr",
    "qatar": "qa", "romania": "ro", "russia": "ru", "rwanda": "rw", "saint-kitts-and-nevis": "kn",
    "saint-lucia": "lc", "saint-vincent-and-the-grenadines": "vc", "samoa": "ws",
    "san-marino": "sm", "sao-tome-and-principe": "st", "saudi-arabia": "sa", "scotland": "gb-sct",
    "senegal": "sn", "serbia": "rs", "seychelles": "sc", "sierra-leone": "sl",
    "singapore": "sg", "slovakia": "sk", "slovenia": "si", "solomon-islands": "sb",
    "somalia": "so", "south-africa": "za", "south-korea": "kr", "south-sudan": "sd",
    "spain": "es", "sri-lanka": "lk", "sudan": "sd", "suriname": "sr", "sweden": "se",
    "switzerland": "ch", "syria": "sy", "tajikistan": "tj", "tanzania": "tz",
    "tahiti": "ti", "thailand": "th", "timor-leste": "tl", "togo": "tg",
    "tonga": "to", "trinidad-and-tobago": "tt", "tunisia": "tn", "turkey": "tr",
    "turkmenistan": "tm", "tuvalu": "tv", "uganda": "ug", "ukraine": "ua",
    "united-arab-emirates": "ae", "united-kingdom": "gb", "united-states-of-america": "us", "uruguay": "uy",
    "samoa-us": "us", "uzbekistan": "uz", "vanuatu": "vu", "venezuela": "ve",
    "vietnam": "vn", "wales": "gb", "yemen": "ye", "yibuti": "yi", "zambia": "zm",
    "zimbabwe": "zw", "world-wide": "ww"
};