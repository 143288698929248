<template>
    <div
        class="modal fade"
        id="crop-image-modal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
    >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body centered-content">
                    <div class="cropper-container">
                        <cropper
                            ref="cropper"
                            backgroundClass="background-whitesmoke"
                            :src="image.src"
                            :default-size="defaultSize"
                            :stencil-props="{
                                aspectRatio: 1,
                            }"
                        />
                    </div>
                    <div class="row w-100 mt-2">
                        <div class="col-6">
                            <button class="btn btn-danger w-100" @click="close">
                                {{ $t("common.cancel") }}
                            </button>
                        </div>
                        <div class="col-6">
                            <button class="btn btn-primary w-100" @click="crop">
                                {{ $t("common.save") }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from "bootstrap";

import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import "vue-advanced-cropper/dist/theme.bubble.css";

export default {
    name: "image-croppper",
    components: {
        Cropper,
    },
    emits: ["update:cropped"],
    data() {
        return {
            modal: null,
            file: null,
            defaultSize: {
                width: 300,
                height: 300,
            },
            image: {
                src: null,
                type: null,
            },
        };
    },
    mounted() {
        this.modal = new Modal("#crop-image-modal");
    },
    beforeUnmount() {
        if (this.image.src) {
            URL.revokeObjectURL(this.image.src);
        }
        this.close();
    },
    methods: {
        crop() {
            const { canvas } = this.$refs.cropper.getResult();
            canvas.toBlob((blob) => {
                let file = new File([blob], this.file.name);
                this.$emit("update:cropped", file);
            }, this.image.type);

            this.close();
        },
        load(file) {
            if (file) {
                this.file = file;

                if (this.image.src) {
                    URL.revokeObjectURL(this.image.src);
                }

                const blob = URL.createObjectURL(file);
                const type = file.type;

                this.image = {
                    src: blob,
                    type: type,
                };
            }
        },
        open() {
            this.modal.show();
        },
        close() {
            this.modal.hide();
            this.reset();
        },
        reset() {
            this.image = {
                src: null,
                type: null,
            };
        },
    },
};
</script>

<style lang="scss">
.cropper-container {
    // width: fit-content;
    // min-width: 300px;
    width: 100%;

    // height: fit-content;
    // min-height: 250px;

    border-radius: 8px;
}

.vue-advanced-cropper__foreground {
    border-radius: 10px;
    opacity: 0.3 !important;
}
</style>