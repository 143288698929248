export const positions = [
    { id: 0, key: "goalkeeper" },
    { id: 2, key: "right-side" },
    { id: 3, key: "left-side" },
    { id: 5, key: "right-central" },
    { id: 6, key: "left-central" },
    { id: 7, key: "pivot" },
    { id: 8, key: "midfielder" },
    { id: 9, key: "attacking-midfielder" },
    { id: 11, key: "right-midfielder" },
    { id: 12, key: "left-midfielder" },
    { id: 13, key: "half-point" },
    { id: 15, key: "right-extreme" },
    { id: 16, key: "left-extreme" },
    { id: 18, key: "second-forward" },
    { id: 19, key: "center-forward" },
    { id: 20, key: "universal" }
]

export function getPosition(id) {
    let pos = positions.find(p => p.id === id);
    return pos;
}