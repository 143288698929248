import Repository from "./repository";

import { usePreferencesStore } from "@/stores/preferences";

const resource = "snapshot";

let _store = null;
var store = () => _store ?? loadStore();

function loadStore() {
    _store = usePreferencesStore();
    return _store;
}

let snapshotsCache = {};

function getCacheKey(id, scoutType) {
    return `${id}_${scoutType}`;
}

function getScoutFromCache(id, scoutType) {
    let snapshot = snapshotsCache[getCacheKey(id, scoutType)];
    return snapshot ?? null;
}

function addScoutToCache(id, scoutType, snapshot) {
    snapshotsCache[getCacheKey(id, scoutType)] = snapshot;
}

export default {
    async add(payload) {
        let endpoint = `${resource}`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async get({ id }) {
        var scoutType = store().getMode;

        let snapshot = getScoutFromCache(id, scoutType);
        if (snapshot !== null) return { data: snapshot };

        let endpoint = `${resource}/${id}/${scoutType}`;
        let response = await Repository.get(endpoint);

        addScoutToCache(id, scoutType, response.data);

        return response;
    },
    async getPositionsWithSnapshots({ id }) {
        let endpoint = `${resource}/${id}/positions`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getSnapshots({ id, position }) {
        snapshotsCache = {}; // Reset cache on change player

        let endpoint = `${resource}/${id}/${position}/list`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async delete({ id }) {
        let endpoint = `${resource}/${id}`;
        let response = await Repository.delete(endpoint);
        return response;
    }
}