import Repository from "./repository";

const resource = "share";

export default {
    async getPlayerShare({ id }) {
        let endpoint = `${resource}/${id}`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async share({ id }) {
        let endpoint = `${resource}/${id}`;
        let response = await Repository.put(endpoint);
        return response;
    },
    async unshare({ id }) {
        let endpoint = `${resource}/${id}`;
        let response = await Repository.delete(endpoint);
        return response;
    },
    async getSharedPlayer({ id }) {
        let endpoint = `${resource}/${id}/player`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getSharedPlayerAverageScout({ id, position, type }) {
        if (type === undefined) type = 0;

        let endpoint = `${resource}/${id}/average-scout/${position}/${type}`;
        let response = await Repository.get(endpoint);
        return response;
    }
}