export const demarcations = [
    { id: 0, key: "goalkeeper" },
    { id: 1, key: "defender" },
    { id: 2, key: "midfield" },
    { id: 3, key: "forward" },
    { id: 4, key: "universal" }
]

export function getDemarcation(id) {
    let dem = demarcations.find(d => d.id === id);
    return dem;
}