import { i18n } from "../../i18n";
let language = i18n.global;

function hasValue(input) {
    return input !== null && input !== undefined && input !== "";
}

import { getPosition } from "@/config/positions.js";
function getPositionLabel(id) {
    if (!hasValue(id)) return "-";
    return language.t(`positions.${getPosition(id).key}.label`);
}

function getPositionAcronym(id) {
    if (!hasValue(id)) return "-";
    return language.t(`positions.${getPosition(id).key}.acronym`);
}

function getPositionKey(id) {
    if (!hasValue(id)) return "-";
    return getPosition(id).key;
}

import { getCategory } from "@/config/categories.js";
function getCategoryLabel(id) {
    if (!hasValue(id)) return "-";
    return language.t(`categories.${getCategory(id).key}.label`)
}

function getCategorySubLabel(id) {
    if (!hasValue(id)) return "-";
    return language.t(`categories.${getCategory(id).key}.sub-range`)
}

function getCategoryCompleteLabel(id) {
    return `${getCategoryLabel(id)} ${getCategorySubLabel(id)}`.trim();
}

import { getFoot } from "@/config/foots.js";
function getFootLabel(id) {
    if (!hasValue(id)) return "-";
    return language.t(`foots.${getFoot(id).key}`);
}

function getCountryLabel(key) {
    if (!hasValue(key)) return "-";
    return language.t(`countries.${key}`);
}

import { codes } from "@/config/countries";
function getCountryCode(key) {
    if (!hasValue(key)) return "-";
    return codes[key];
}

function parseHeight(height) {
    if (!hasValue(height)) return "-";
    return `${height}cm`;
}

function parseNumber(number) {
    if (!hasValue(number)) return "-";
    return number;
}

import { getDemarcation } from "@/config/demarcations";
function getDemarcationLabel(demarcation) {
    if (!hasValue(demarcation)) return "-";
    return language.t(`demarcations.${getDemarcation(demarcation).key}`);
}

import { getOrientation } from "@/config/orientations";
function getOrientationLabel(orientation) {
    if (!hasValue(orientation)) return "-";
    return language.t(`orientations.${getOrientation(orientation).key}`);
}

import { getViewingType } from "@/config/viewing-types";
function getViewing(type) {
    return getViewingType(type);
}

function parseMarketValue(marketValue) {
    if (!hasValue(marketValue)) return "-";
    return marketValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + '€';
}

function parseEndContractYear(year) {
    if (!hasValue(year)) return "-";
    return year;
}

function parseBoolean(value) {
    let bool = value === true ? 'yes' : 'no';
    return language.t(`common.${bool}`);
}

import moment from "moment";
function parseDateAndTimeUTC(date) {
    return moment.utc(date).local().format("DD/MM/YYYY HH:mm:ss");
}

function parseDate(date) {
    if (!hasValue(date)) return "-";
    return moment(date).format("DD/MM/YYYY");
}

function parseAge(date) {
    if (date === null) return "-";

    let birthDate = moment(date, "YYYY-MM-DD");
    let age = moment().diff(birthDate, "years");
    return age;
}

function parseRecordType(type) {
    return type === 0 ? language.t('record.public') : language.t('record.private');
}

function getLanguage(lang, keys) {
    var value = language.messages.value[lang];
    for (const key of keys.split('.')) {
        value = value[key];
    }
    return value;
}

export const parser = {
    getPositionLabel,
    getPositionAcronym,
    getPositionKey,
    getCategoryLabel,
    getCategorySubLabel,
    getCategoryCompleteLabel,
    getFootLabel,
    getCountryLabel,
    getCountryCode,
    parseHeight,
    parseNumber,
    getDemarcationLabel,
    getOrientationLabel,
    getViewing,
    parseMarketValue,
    parseEndContractYear,
    parseBoolean,
    parseDateAndTimeUTC,
    parseDate,
    parseAge,
    parseRecordType,
    getLanguage
}