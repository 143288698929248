export const viewingTypes = [
    { key: 0, label: "live", icon: "eye" },
    { key: 1, label: "tv", icon: "tv" },
    { key: 2, label: "record", icon: "video-camera" },
    { key: 3, label: "highlights", icon: "scissors" }
]

export function getViewingType(id) {
    let view = viewingTypes.find(v => v.key === id);
    return view;
}