<template>
    <td class="rating-cell">
        <div class="w-100 centered-content">
            <div class="rating-container centered-content" :class="tdClass">
                <template v-if="isN0">
                    {{ rating }}{{ item.leagueLevel }}
                </template>
                <template v-else>
                    {{ rating }}
                </template>
            </div>
        </div>
    </td>
</template>

<script>
import { getColorClass } from "@/services/helpers/scout.js";

export default {
    name: "rating-cell",
    props: {
        column: {
            type: Object,
            default: () => {},
        },
        item: {
            type: [Object, Number],
            required: true,
        },
    },
    computed: {
        isN0() {
            return this.column?.name === "n0";
        },
        rating() {
            let rating = this.item?.rating ?? this.item;
            return this.$helper.getRoundedScoreToDecimals(rating);
        },
        tdClass() {
            return getColorClass(this.rating, this.isComplete);
        },
        isComplete() {
            if (this.isN0) {
                return this.rating > 0;
            } else {
                return this.item?.complete ?? true;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
td {
    padding: 0 0.1rem;
}

.rating-container {
    border-radius: 5px;
    font-weight: bold;
    width: 52.5px;
    height: 50px;
}

.rating-cell {
    width: 0px; // make td fit width to content
}
</style>