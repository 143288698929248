<template>
    <div v-if="showSearch" class="row g-2 justify-content-end p-1">
        <div class="col d-flex justify-content-end">
            <slot name="pre-search" />
        </div>
        <div class="col-12 col-sm-5 col-lg-3">
            <div class="input-group mb-1">
                <input
                    type="text"
                    v-model="search"
                    class="form-control small-input-text"
                    :placeholder="$t('tables.search')"
                    @keypress.enter="getItems"
                />
                <span
                    v-if="search !== ''"
                    class="input-group-text is-pointer"
                    @click="clearSearch"
                >
                    <Icon name="x" helperClass="text-danger" />
                </span>
                <span class="input-group-text is-pointer" @click="getItems">
                    <Icon name="magnifying-glass" />
                </span>
            </div>
        </div>
    </div>

    <div class="table-responsive">
        <table class="table table-hover">
            <ScoutyouTableHeader :columns="columns" v-model:sorting="sort" />
            <Transition name="fade" mode="out-in">
                <tbody v-if="loading">
                    <tr>
                        <td
                            class="left-border right-border"
                            :colspan="columns.length"
                        >
                            <Loading />
                            <div class="text-center text-primary">
                                <strong>{{ $t("tables.loading") }}</strong>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr v-for="item of tableItems" :key="item">
                        <template v-for="column in columns" :key="column.name">
                            <RatingCell
                                v-if="column.type === 'rating'"
                                :column="column"
                                :item="text(item, column)"
                            />
                            <td
                                v-else
                                :class="column.tdClass"
                                @click="rowClicked(item, column)"
                            >
                                <slot :name="column.name" :data="{ item }">
                                    {{ text(item, column) }}
                                </slot>
                            </td>
                        </template>
                    </tr>
                    <tr v-if="tableItems.length === 0">
                        <td
                            class="text-center no-border"
                            :colspan="columns.length"
                        >
                            {{ $t(`tables.empty.${empty}`) }}
                        </td>
                    </tr>
                </tbody>
            </Transition>
            <tfoot>
                <slot name="custom-foot" />
            </tfoot>
        </table>
    </div>
    <nav v-if="!loading" class="row px-1 justify-content-end">
        <div v-if="showSummary" class="col centered-content text-center">
            {{ tableSummary }}
        </div>
        <div class="col-auto">
            <div class="pagination justify-content-end is-pointer">
                <div class="page-item" @click="first()">
                    <div class="page-link">
                        <Icon name="chevron-left" />
                        <Icon name="chevron-left" />
                    </div>
                </div>
                <div class="page-item" @click="prev()">
                    <div class="page-link">
                        <Icon name="chevron-left" />
                    </div>
                </div>

                <div
                    v-for="i in avaiablePages"
                    :key="i"
                    class="page-item"
                    :class="{ active: i === page }"
                    @click="go(i)"
                >
                    <div class="page-link">{{ i }}</div>
                </div>

                <div class="page-item" @click="next()">
                    <div class="page-link">
                        <Icon name="chevron-right" />
                    </div>
                </div>
                <div class="page-item" @click="last()">
                    <div class="page-link">
                        <Icon name="chevron-right" />
                        <Icon name="chevron-right" />
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import { paginate } from "@/services/pagination";

import ScoutyouTableHeader from "./Header.vue";
import RatingCell from "./RatingCell.vue";

export default {
    name: "scout-you-table",
    emits: ["row-clicked", "update:page"],
    components: {
        ScoutyouTableHeader,
        RatingCell,
    },
    props: {
        columns: {
            type: Array,
            default: () => [],
        },
        loading: {
            type: Boolean,
            default: true,
        },
        page: {
            type: Number,
            default: 1,
        },
        take: {
            type: Number,
            default: 10,
        },
        total: {
            type: Number,
            default: 0,
        },
        empty: {
            type: String,
            default: "",
        },
        items: {
            type: [Array, Function],
        },
        loadOnMounted: {
            type: Boolean,
            default: true,
        },
        showSearch: {
            type: Boolean,
            default: true,
        },
        showSummary: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            search: "",
            tableItems: [],
            sort: {
                by: null,
                order: "asc",
            },
        };
    },
    mounted() {
        if (this.loadOnMounted) {
            this.getItems();
        }
    },
    computed: {
        pages() {
            if (this.take === 0) return 0;
            return Math.ceil(this.total / this.take);
        },
        avaiablePages() {
            const current = this.page;
            const total = this.pages;

            if (total <= 1) return [1];

            const center = [
                current - 2,
                current - 1,
                current,
                current + 1,
                current + 2,
            ];
            const filteredCenter = center.filter((p) => p > 1 && p < total);
            const includeThreeLeft = current === 5;
            const includeThreeRight = current === total - 4;
            const includeLeftDots = current > 5;
            const includeRightDots = current < total - 4;

            if (includeThreeLeft) filteredCenter.unshift(2);
            if (includeThreeRight) filteredCenter.push(total - 1);

            if (includeLeftDots) filteredCenter.unshift("...");
            if (includeRightDots) filteredCenter.push("...");

            return [1, ...filteredCenter, total];
        },
        tableSummary() {
            let page = this.page;
            let take = this.take;
            let total = this.total;

            let start = (page - 1) * take + 1;
            let end = page * take;
            if (end > total) {
                end = total;
            }

            return this.$t("tables.summary")
                .replace("#start#", start)
                .replace("#end#", end)
                .replace("#total#", total);
        },
    },
    watch: {
        page() {
            this.getItems();
        },
        take() {
            this.getItems();
        },
        sort: {
            deep: true,
            handler() {
                this.getItems();
            },
        },
        items: {
            deep: true,
            handler() {
                if (typeof this.items !== "function") {
                    this.getItems();
                    this.go(1);
                }
            },
        },
    },
    methods: {
        async refresh() {
            await this.getItems();
        },
        clear() {
            this.tableItems = [];
        },
        async clearSearch() {
            this.search = "";
            await this.refresh();
        },
        async getItems() {
            let items = [];
            if (typeof this.items === "function") {
                items = await this.items({
                    page: this.page,
                    take: this.take,
                    search: this.search,
                    sort: this.sort,
                });
            } else {
                items = paginate(this.items, this.page, this.take);
            }
            this.tableItems = items;
        },
        text(item, column) {
            if (column.formatter) {
                return column.formatter(item);
            } else {
                return item[column.name];
            }
        },
        rowClicked(item, column) {
            if (column.noClick) return;
            this.$emit("row-clicked", item);
        },
        first() {
            if (this.page > 1) this.go(1);
        },
        prev() {
            if (this.page > 1) this.go(this.page - 1);
        },
        go(page) {
            this.$emit("update:page", page);
        },
        next() {
            if (this.page < this.pages) this.go(this.page + 1);
        },
        last() {
            if (this.page < this.pages) this.go(this.pages);
        },
    },
};
</script>

<style lang="scss" scoped>
.table-responsive {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}

td {
    padding: 0.25rem;
}

tr {
    cursor: pointer;
}

tfoot {
    border: none;
    border-color: transparent;
}

.left-border {
    border-left-width: 1px;
}

.right-border {
    border-right-width: 1px;
}

.small-input-text {
    width: 10rem;
}
</style>