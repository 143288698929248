<template>
    <span :class="`fi fi-${code}`" />
</template>

<script>
// https://www.iso.org/obp/ui/#search
// https://gist.github.com/themeteorchef/dcffd74ca3ab45277c81
export default {
    name: "country-flag",
    props: {
        country: {
            type: String,
            default: null,
        },
    },
    computed: {
        code() {
            return this.$parser.getCountryCode(this.country);
        },
    },
};
</script>