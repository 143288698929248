<template>
    <nav class="navbar" :class="{ opened: opened }">
        <div class="navbar-nav">
            <div class="logo">
                <img
                    src="@/assets/images/logo1.png"
                    height="35"
                    class="large-logo"
                />
                <img
                    src="@/assets/images/logo-simple.png"
                    height="35"
                    class="small-logo"
                />
            </div>

            <div v-for="route in routes" :key="route.key" class="nav-item">
                <a
                    class="nav-link"
                    :class="{ active: isRoute(route.key) }"
                    @click="go(route.key)"
                >
                    <CustomIcon :name="route.icon" />
                    <span class="link-text">
                        {{ $t(`navbar.${route.key}`) }}
                    </span>
                </a>
            </div>

            <div class="nav-item hide-less-md">
                <div
                    class="nav-link collapse-button justify-content-end"
                    @click="toggle"
                >
                    <div v-show="opened">
                        <Icon name="chevron-left" />
                        <Icon name="chevron-left" />
                    </div>
                    <div v-show="!opened">
                        <Icon name="chevron-right" />
                        <Icon name="chevron-right" />
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: "vertical-navbar",
    data() {
        return {
            opened: false,
            routes: [],
        };
    },
    mounted() {
        this.computeRoutes();
    },
    methods: {
        toggle() {
            this.opened = !this.opened;
        },
        isRoute(name) {
            return this.$route.name === name;
        },
        go(name) {
            this.$router.push({ name: name });
        },
        computeRoutes() {
            let routes = [];

            if (this.$permissions.allowRecords())
                routes.push({ key: "main-record", icon: "thumbtack" });

            if (this.$permissions.allowClub())
                routes.push({ key: "club", icon: "group" });

            routes.push({ key: "players", icon: "player" });
            routes.push({ key: "scouts", icon: "binoculars" });
            routes.push({ key: "leagues", icon: "trophy" });
            routes.push({ key: "teams", icon: "shield" });

            if (this.$permissions.allowMatches())
                routes.push({ key: "matches", icon: "vs" });

            if (this.$permissions.allowRecords())
                routes.push({ key: "records", icon: "list" });

            if (this.$permissions.allowSearch())
                routes.push({ key: "search", icon: "magnifying-glass" });

            if (this.$permissions.allowUsers())
                routes.push({ key: "users", icon: "users" });

            if (this.$permissions.allowMetrics())
                routes.push({ key: "metrics", icon: "chart" });

            this.routes = routes;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";

$transition-speed: 400ms;

.navbar {
    z-index: 10;
    background-color: $navbar-background;
    transition: width $transition-speed ease;
}

.navbar-nav {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;

    overflow-y: auto;
}

.nav-item {
    width: 100%;
}

.nav-link {
    display: flex;
    align-items: center;
    color: $navbar-text-color;
    text-decoration: none;
    cursor: pointer;
    transition: $transition-speed;

    &.active {
        color: $navbar-text-color;
        background: #141418;
    }
}

.nav-link:hover {
    background: #141418;
    color: #ececec;
}

.link-text {
    display: none;
    margin-left: 1rem;
}

.fa-primary {
    color: #ff7eee;
}

.fa-secondary {
    color: #df49a6;
}

.fa-primary,
.fa-secondary {
    transition: $transition-speed;
}

/* Large screens */
@media only screen and (min-width: 768px) {
    .navbar {
        position: relative;
        width: 5rem;
        height: 100vh;
        padding: 0;
        top: 0;
    }

    .navbar-nav {
        overflow-x: hidden;
    }

    .navbar {
        &.opened {
            width: 16rem;
            .link-text {
                display: inline;
            }

            .small-logo {
                display: none;
            }
        }

        &:not(.opened) {
            .large-logo {
                display: none;
            }
        }

        .nav-link {
            height: 3.5rem;
            padding-left: 1.5rem;
            padding-right: 2rem;
        }
    }

    .nav-item:last-child {
        margin-top: auto;
    }

    .logo {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}

/* Small screens */
@media only screen and (max-width: 767px) {
    .navbar {
        position: fixed;
        right: 0;
        left: 0;
        bottom: 0;

        padding: 0;

        width: 100vw;
        height: 4rem;
    }

    .navbar-nav {
        flex-direction: row;

        overflow-x: auto;
    }

    .nav-item {
        height: 100%;
        min-width: 3.5rem;
    }

    .nav-link {
        height: 100%;
        justify-content: center;
    }

    .logo {
        margin: 0rem 0.75rem;
    }

    .large-logo {
        display: none;
    }

    .collapse-button {
        display: none;
    }
}
</style>