<template>
    <div class="rating" :class="[rating.class, size]" @click="$emit('click')">
        <div class="number">
            <slot>
                {{ rating }}
            </slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "rating-display",
    props: {
        rating: {
            type: Object,
            required: true,
        },
        size: {
            type: String,
            default: "big",
        },
    },
    emits: ["click"],
};
</script>

<style lang="scss" scoped>
.rating {
    cursor: pointer;

    text-align: center;

    min-width: 8rem;
    width: fit-content;

    border-radius: 5px;
    box-shadow: 0rem 0rem 0.5rem gray;

    .number {
        font-size: 3.25rem;
        font-weight: bold;

        padding: 1.25rem;
    }
}
</style>