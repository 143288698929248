import Repository from "./repository";

import { usePreferencesStore } from "@/stores/preferences";
import { buildPaginationQuery } from "../pagination/index";

const resource = "scout"

let _store = null;
var store = () => _store ?? loadStore();

function loadStore() {
    _store = usePreferencesStore();
    return _store;
}

export default {
    async initialize(payload) {
        let endpoint = `${resource}`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async get({ id }) {
        let endpoint = `${resource}/${id}/${store().getMode}`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async update(payload) {
        let endpoint = `${resource}/update/${payload.id}`;

        payload.scoutType = store().getMode;

        let response = await Repository.put(endpoint, payload);
        return response;
    },
    async delete({ id }) {
        let endpoint = `${resource}/${id}`
        let response = await Repository.delete(endpoint);
        return response;
    },
    async getPaged(params) {
        let endpoint = `${resource}/paged`;
        endpoint = buildPaginationQuery(endpoint, params);
        let response = await Repository.get(endpoint);
        return response;
    },
    async getUserScoutsPaged(params) {
        let endpoint = `${resource}/byUser/paged`;
        endpoint = buildPaginationQuery(endpoint, params);
        let response = await Repository.get(endpoint);
        return response;
    },
    async getUserFavoriteScoutsPaged(params) {
        let endpoint = `${resource}/favorites/byUser/paged`;
        endpoint = buildPaginationQuery(endpoint, params);
        let response = await Repository.get(endpoint);
        return response;
    },
    async getPlayerScouts({ id }) {
        let endpoint = `${resource}/player/${id}`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getPlayerAverageScout({ id, position }) {
        let endpoint = `${resource}/${id}/${position}/summary/${store().getMode}`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getAllPlayerScouts({ id }) {
        let endpoint = `${resource}/player/${id}/all`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getScoutShare({ id }) {
        let endpoint = `${resource}/${id}/share`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async share({ id }) {
        let endpoint = `${resource}/${id}/share`;
        let response = await Repository.put(endpoint);
        return response;
    },
    async unshare({ id }) {
        let endpoint = `${resource}/${id}/share`;
        let response = await Repository.delete(endpoint);
        return response;
    },
    async getSharedScout({ id }) {
        let endpoint = `${resource}/${id}/share/complete`;
        let response = await Repository.get(endpoint);
        return response;
    },
}